import React from "react";
import styles from "./styles/styles.module.css";
import { emailResolutionTypes } from "../../types/emailTypes";
import { ReactComponent as Shield } from "../../assets/blockShield.svg";
import { ReactComponent as Bell } from "../../assets/pendingBell.svg";
import { ReactComponent as Exclaim } from "../../assets/exclaim.svg";
import { ReactComponent as GreenCheck } from "../../assets/greenBtnCheckmark.svg";
import { ReactComponent as RedX } from "../../assets/redX.svg";

interface MessageApprovalInterface {
  approval: string;
}

const MessageApproval: React.FC<MessageApprovalInterface> = ({ approval }) => {
  function returnData() {
    switch (approval) {
      case emailResolutionTypes.BYPASSED:
        return {
          styling: { border: "1px solid rgba(228, 57, 53, 0.50)" },
          headline: "Message blocked",
          rule: "Recipient count > 5",
          ruleText: "Rule: ",
          reason: "Bypassed",
          icon: (
            <div className={styles.iconRed}>
              <Shield />{" "}
            </div>
          ),
          rightContainer: (
            <div className={styles.blockContainer}>
              <Exclaim className={styles.exclaim} />
              <p style={{ color: "#E43935" }} className={styles.reason}>
                Bypassed
              </p>
              <p className={styles.dash}>|</p>
              <p className={styles.view}>View</p>
            </div>
          ),
        };
      case emailResolutionTypes.FLAGGED:
        return {
          styling: { border: "1px solid rgba(228, 57, 53, 0.50)" },
          headline: "Message flagged",
          ruleText: "Rule: ",
          rule: "Recipient count > 5",
          reason: "Flagged",
          icon: (
            <div className={styles.iconRed}>
              <Shield />{" "}
            </div>
          ),
          rightContainer: (
            <div className={styles.blockContainer}>
              <Exclaim />
              <p style={{ color: "#E43935" }} className={styles.reason}>
                Flagged
              </p>
            </div>
          ),
        };
      case emailResolutionTypes.PENDING:
        return {
          styling: { border: "0.75px solid #FA901B" },
          headline: "Message flagged",
          ruleText: "Rule: ",
          rule: "Recipient count > 5",
          reason: "Flagged",
          icon: (
            <div className={styles.iconOrange}>
              <Bell />
            </div>
          ),
          rightContainer: (
            <div className={styles.confirmContainer}>
              <button className={styles.btnGreen}>
                <GreenCheck className={styles.greenCheck} />
                Send
              </button>

              <button className={styles.btnRed}>
                <RedX className={styles.redCheck} />
                Block
              </button>
            </div>
          ),
        };
    }
  }

  return (
    <div style={returnData()?.styling} className={styles.wrapper}>
      <div className={styles.dataContainer}>
        <div className={styles.subData}>
          {returnData()?.icon}
          <div className={styles.textContainer}>
            <p className={styles.headline}>{returnData()?.headline}</p>
            <div className={styles.ruleContainer}>
              <p className={styles.rule}>{returnData()?.ruleText}</p>
              <p className={styles.ruleData}>{returnData()?.rule}</p>
            </div>
          </div>
        </div>
        {returnData()?.rightContainer}
      </div>
    </div>
  );
};

export default MessageApproval;

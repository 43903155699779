import { expandMonthName } from "./expandMonthName";
import showPastSixMonths from "./showPastSixMonths"; // Adjust the path as needed

interface Incidents {
  [key: string]: number;
}

function getIncidentDataForPastSixMonths(incidents: Incidents): number[] {
  const pastSixMonths = showPastSixMonths();
  return pastSixMonths.map((month) => {
    const fullMonthName = expandMonthName(month);
    return incidents[fullMonthName] || 0;
  });
}

export default getIncidentDataForPastSixMonths;

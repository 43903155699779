import React from "react";
import styles from "./styles/styles.module.css";
import { eventInputTypes as inputTypes } from "../../types/eventInputTypes";
import DatePicker from "react-datepicker";
import { ReactComponent as CalendarIcon } from "../../assets/calendarSymbol.svg";
import "react-datepicker/dist/react-datepicker.css";

interface InputElementInterface {
  label: string;
  type: inputTypes;
  items: string[] | null;
}

const InputElement: React.FC<InputElementInterface> = ({
  label,
  type,
  items,
}) => {
  function returnField() {
    switch (type) {
      case inputTypes.REGULAR:
        return (
          <>
            <p className={styles.label}>{label}</p>
            <input type="text" className={styles.inputElement} />
          </>
        );

      case inputTypes.DROPDOWN:
        return (
          <>
            <p className={styles.label}>{label}</p>
            <select className={styles.inputElementDropdown}>
              {items?.map((item: string) => {
                return (
                  <option key={`events_dropdown_item_${item} `}>{item}</option>
                );
              })}
            </select>
          </>
        );
      case inputTypes.CALENDAR:
        return (
          <>
            <p className={styles.label}>{label}</p>
            <div className={styles.datePickerContainer}>
              <CalendarIcon className={styles.calendarIcon} />
              <DatePicker
                className={styles.calendarInput}
                selected={new Date()}
                onChange={(date) => () => {}}
              />
            </div>
          </>
        );
    }
  }

  return <div className={styles.wrapper}>{returnField()}</div>;
};

export default InputElement;

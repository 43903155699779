import React, { useState } from "react";
import styles from "./styles/styles.module.css";
import InputElement from "../../components/InputElement/InputElement";
import { eventInputTypes as inputTypes } from "../../types/eventInputTypes";
import CustomCheckBox from "../../components/CustomCheckBox/CustomCheckBox";
import { ReactComponent as FilledCaretDown } from "../../assets/CarretFilledDown.svg";
import EmailPreview from "../../components/EmailPreview/EmailPreview";
import { mockEmailData } from "./mockData";
import { ReactComponent as Bookmark } from "../../assets/bookmark.svg";
import { ReactComponent as Share } from "../../assets/shareLink.svg";
import NoEmailSelected from "../../components/NoEmailSelected/NoEmailSelected";
import MessageContainer from "../../components/MessageContainer/MessageContainer";
import EmailAssignmentContainer from "../../components/EmailAssignmentContainer/EmailAssignmentContainer";

const Events: React.FC<any> = () => {
  const [selectedMessageTabIndex, setSelectedMessageTabIndex] =
    useState<number>(0);
  const [selectedEmailIndex, setSelectedEmailIndex] = useState<number | null>(
    null,
  );
  const [checkboxState, setCheckboxState] = useState({
    0: false,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.topInputRow}>
        <InputElement
          label="Search by keyword or phrase:"
          type={inputTypes.REGULAR}
          items={null}
        />
        <InputElement
          label="Include:"
          type={inputTypes.DROPDOWN}
          items={["Include1", "Include2"]}
        />
        <InputElement
          label="Status:"
          type={inputTypes.DROPDOWN}
          items={["Status1", "Status2"]}
        />
        <InputElement
          label="Date range:"
          type={inputTypes.CALENDAR}
          items={null}
        />
      </div>
      <div className={styles.bottomInputRow}>
        <InputElement
          label="Sender:"
          type={inputTypes.DROPDOWN}
          items={["Include1", "Include2"]}
        />
        <InputElement
          label="Recipient:"
          type={inputTypes.REGULAR}
          items={null}
        />
        <InputElement
          label="Assigned to:"
          type={inputTypes.DROPDOWN}
          items={["Status1", "Status2"]}
        />

        <InputElement
          label="Domain:"
          type={inputTypes.DROPDOWN}
          items={["Status1", "Status2"]}
        />
      </div>

      <div className={styles.saveSearchContainer}>
        <p className={styles.loadSaveText}>Save selected search</p>
        <p className={styles.loadSaveTextDivider}>|</p>
        <select className={styles.loadSearch}>
          <option value="" disabled selected>
            Load saved search
          </option>
        </select>
      </div>

      <div className={styles.eventContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.bulkDropdownContainer}>
            <CustomCheckBox
              allData={[]}
              checkAll={true}
              index={0}
              state={checkboxState}
              setState={setCheckboxState}
            />
            <select className={styles.bulkName}>
              <option value="" disabled selected>
                Bulk actions
              </option>
            </select>
            <FilledCaretDown className={styles.caretDown} />
          </div>

          {mockEmailData.map((data: any, index: number) => {
            return (
              <EmailPreview
                date={data.date}
                badgeStatus={data.resolutionStatus}
                checkboxState={checkboxState}
                setCheckboxState={setCheckboxState}
                numberOfEmails={data.numberOfEmails}
                name={data.name}
                subject={data.subject}
                setIndex={setSelectedEmailIndex}
                selectedIndex={selectedEmailIndex}
                index={index}
                severity={data.severity}
              />
            );
          })}
        </div>

        <div className={styles.rightContainer}>
          {selectedEmailIndex !== null ? (
            <div className={styles.rightTopBar}>
              <p className={styles.emailNumber}>
                {selectedEmailIndex !== null
                  ? mockEmailData[selectedEmailIndex].emailNumber
                  : null}
              </p>

              <div className={styles.shareBookmarkContainer}>
                <div
                  style={{ marginRight: "22px" }}
                  className={styles.shareBookmarkData}
                >
                  <Share className={styles.shareIcon} />
                  <p className={styles.shareBookmarkText}>Share</p>
                </div>
                <div className={styles.shareBookmarkData}>
                  <Bookmark className={styles.bookmarkIcon} />
                  <p className={styles.shareBookmarkText}>Bookmark</p>
                </div>
              </div>
            </div>
          ) : (
            <NoEmailSelected />
          )}
          {selectedEmailIndex !== null && (
            <MessageContainer
              messages={mockEmailData[selectedEmailIndex]?.messages ?? []}
              recipientEmails={
                mockEmailData[selectedEmailIndex].recipientEmails
              }
              logs={mockEmailData[selectedEmailIndex]?.logs}
              invoices={mockEmailData[selectedEmailIndex]?.invoices}
              senderEmail={mockEmailData[selectedEmailIndex]?.senderEmail}
              senderName={mockEmailData[selectedEmailIndex]?.senderName}
              selectedIndex={selectedMessageTabIndex}
              setSelectedIndex={setSelectedMessageTabIndex}
              messageApprovalState={
                mockEmailData[selectedEmailIndex]?.resolutionStatus
              }
            />
          )}
          {selectedEmailIndex !== null && <EmailAssignmentContainer />}
        </div>
      </div>
    </div>
  );
};

export default Events;

export default function createUserStateObject(verify: any) {
  return {
    profilePic: verify?.user?.profilePic,
    firstName: verify?.user?.firstName,
    lastName: verify?.user?.lastName,
    active: verify?.user?.active,
    role: verify?.user?.role,
    company: verify?.user?.companyName,
    email: verify?.user?.email,
    incidents: verify?.user?.incidents,
    flaggedEmails: verify?.user?.flaggedEmails,
    tokenExpiration: verify?.user?.exp,
    companyUsers: verify?.user?.companyUsers,
    companyDomains: verify?.user?.companyDomains,
    companyRoles: verify?.user?.companyRoles,
    companyGroups: verify?.user?.companyGroups,
    add_new_emails: verify?.user?.add_new_emails,
    review_all_emails: verify?.user?.review_all_emails,
    permission_3: verify?.user?.permission_3,
    permission_4: verify?.user?.permission_4,
    permission_5: verify?.user?.permission_5,
  };
}

import React from "react";
import styles from "./styles/styles.module.css";
import MessageContainerLogSteps from "../MessageContainerLogSteps/MessageContainerLogSteps";

interface MessageContainerLogsInterface {
  logData: any[];
}

const MessageContainerLogs: React.FC<MessageContainerLogsInterface> = ({
  logData,
}) => {
  return (
    <div className={styles.wrapper}>
      {logData.map((dta: any, index: number) => {
        return (
          <MessageContainerLogSteps
            actionTime={dta?.date}
            action={dta?.type}
            firstMessage={index === 0}
            redDot={dta.redDot}
            updatedBy={dta?.by}
          />
        );
      })}
    </div>
  );
};

export default MessageContainerLogs;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import styles from "./globalStyles/styles.module.css";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Dashboard from "./pages/Dashboard/Dashboard";
import RedirectToDashboard from "./pages/Dashboard/util/RedirectToDashboard";
import Layout from "./layout/Layout";
import Users from "./pages/Users/Users";
import AddUsers from "./pages/AddUsers/AddUsers";
import { useSelector } from "react-redux";
import Events from "./pages/Events/Events";

function App() {
  const allowedToAddUsers = useSelector(
    (state: any) => state.user.add_new_emails,
  );
  return (
    <div className={styles.pageWrapper}>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={RedirectToDashboard} />
          <Route path="/login" Component={Login} />

          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                component={() => (
                  <Layout>
                    <Dashboard />
                  </Layout>
                )}
              />
            }
          />

          <Route
            path="/users"
            element={
              <PrivateRoute
                component={() => (
                  <Layout>
                    <Users />
                  </Layout>
                )}
              />
            }
          />

          {allowedToAddUsers && (
            <Route
              path="/users/add-new"
              element={
                <PrivateRoute
                  component={() => (
                    <Layout>
                      <AddUsers />
                    </Layout>
                  )}
                />
              }
            />
          )}
          {allowedToAddUsers && (
            <Route
              path="/users/add-system-user"
              element={
                <PrivateRoute
                  component={() => (
                    <Layout>
                      <AddUsers />
                    </Layout>
                  )}
                />
              }
            />
          )}

          <Route
            path="/events"
            element={
              <PrivateRoute
                component={() => (
                  <Layout>
                    <Events />
                  </Layout>
                )}
              />
            }
          />

          <Route
            path="/reporting"
            element={
              <PrivateRoute
                component={() => (
                  <Layout>
                    <p>Reporting</p>
                  </Layout>
                )}
              />
            }
          />

          <Route
            path="/case_management"
            element={
              <PrivateRoute
                component={() => (
                  <Layout>
                    <p>Case Management</p>
                  </Layout>
                )}
              />
            }
          />

          <Route
            path="/settings"
            element={
              <PrivateRoute
                component={() => (
                  <Layout>
                    <p>Settings</p>
                  </Layout>
                )}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { FC } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto"; // Automatically registers the necessary Chart.js components
import showPastSixMonths from "../utils/showPastSixMonths";
import { useSelector } from "react-redux";
import getIncidentDataForPastSixMonths from "../utils/getIncidentDataForPastSixMonths";

const LineChart: FC = () => {
  const { incidents } = useSelector((state: any) => state.user);

  const data = {
    labels: showPastSixMonths(),
    datasets: [
      {
        label: "Incident detection this month",
        data: getIncidentDataForPastSixMonths(incidents),
        backgroundColor: "rgba(31, 71, 244, 0.2)", // Change to rgba of #1F47F4
        borderColor: "#1F47F4", // Change to #1F47F4
        borderWidth: 2,
        pointBackgroundColor: "#1F47F4", // Change to #1F47F4
        pointBorderColor: "#FFFFFF", // Optional: white border around the points for better visibility
        pointBorderWidth: 2, // Optional: border width of the points
      },
    ],
  };

  const options = {
    responsive: true,
    animation: {
      duration: 0, // Disable animation
    },
    maintainAspectRatio: false,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines
        },
        border: {
          display: false, // Hide the border line
        },
        ticks: {
          color: "#818181", // Change color to match the style
        },
      },
      y: {
        display: false, // Hide the y-axis
      },
    },
    elements: {
      line: {
        tension: 0, // Set tension to 0 for straight lines
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default LineChart;

/* eslint-disable */

import React, { useEffect, useState } from "react";
import styles from "./styles/styles.module.css";
import UsersHeader from "../../components/UsersHeader/UsersHeader";
import { useSelector } from "react-redux";
import moment from "moment";
import Pagination from "../../components/Pagination/Pagination";
import { EmployeeTypeSwitch } from "../../components/EmployeeTypeSwitch/EmployeeTypeSwitch";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CarretFilledDown } from "../../assets/CarretFilledDown.svg";
import CustomCheckBox from "../../components/CustomCheckBox/CustomCheckBox";
import { RxCaretDown } from "react-icons/rx";

const Users: React.FC = () => {
  const navigate = useNavigate();
  const { currentPosition } = useSelector((state: any) => state.slider);
  const allowedToAddUsers = useSelector(
    (state: any) => state.user.add_new_emails,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const { companyUsers } = useSelector((state: any) => state.user);
  const itemsPerPage = 20;

  const [checkboxState, setCheckboxState] = useState<{
    [key: number]: boolean;
  }>({
    0: false,
    1: false,
  });

  // Calculate the indices of the first and last items on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const [users, setUsers] = useState([...companyUsers]);

  // Slice the data to only include items for the current page
  const currentItems = users?.slice(indexOfFirstItem, indexOfLastItem);

  function displayEmployeeTypeLink() {
    switch (currentPosition) {
      case "Left":
        return (
          <p
            onClick={() => navigate("/users/add-system-user")}
            className={styles.employeeLink}
          >
            + Add system users
          </p>
        );
      case "Right":
        return (
          <p
            onClick={() => navigate("/users/add-new")}
            className={styles.employeeLink}
          >
            + Add employees
          </p>
        );
    }
  }

  useEffect(() => {
    const initialState = {
      0: false,
      1: false,
    };

    currentItems.forEach((item: any, index: number) => {
      (initialState as any)[index + 2] = checkboxState[index + 2] ?? false;
    });

    setCheckboxState(initialState);
  }, []);

  return (
    <div className={styles.wrapper}>
      {allowedToAddUsers && (
        <div className={styles.switchContainer}>
          <EmployeeTypeSwitch /> {displayEmployeeTypeLink()}{" "}
        </div>
      )}
      <UsersHeader usersSetter={setUsers} modifiedUsers={users} />

      <div className={styles.bulkActionsWrapper}>
        <CustomCheckBox
          allData={currentItems}
          checkAll={true}
          state={checkboxState}
          index={0}
          setState={setCheckboxState}
        />
        <select className={styles.bulkActions} defaultValue="">
          <option value="" disabled hidden>
            Bulk actions
          </option>
          <option value="action1">Action 1</option>
          <option value="action2">Action 2</option>
        </select>
        <CarretFilledDown className={styles.caretDown} />
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <colgroup>
            <col style={{ width: "15%" }} />
            <col style={{ width: "17%" }} />
            <col style={{ width: "5%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "5%" }} />
          </colgroup>
          <thead className={styles.tableHead}>
            <tr className={styles.topTableRow}>
              <th className={styles.headItem1}>
                <CustomCheckBox
                  allData={currentItems}
                  checkAll={true}
                  state={checkboxState}
                  index={1}
                  setState={setCheckboxState}
                />
                <p className={styles.name}>Name</p>
                <div className={styles.caretOutline}>
                  <RxCaretDown
                    style={{ marginTop: 0, marginBottom: 0 }}
                    size="100%"
                  />
                </div>
              </th>
              <th className={styles.headItem}>
                <div className={styles.headerCellContent}>
                  <p className={styles.name2}>Email address</p>
                  <div className={styles.caretOutline}>
                    <RxCaretDown
                      style={{ marginTop: 0, marginBottom: 0 }}
                      size="100%"
                    />
                  </div>
                </div>
              </th>
              <th className={styles.headItem}>
                <div className={styles.headerCellContent}>
                  <p className={styles.name2}>Role</p>
                  <div className={styles.caretOutline}>
                    <RxCaretDown
                      style={{ marginTop: 0, marginBottom: 0 }}
                      size="100%"
                    />
                  </div>
                </div>
              </th>
              <th className={styles.headItem}>
                <div className={styles.headerCellContent}>
                  <p className={styles.name2}>Group</p>
                  <div className={styles.caretOutline}>
                    <RxCaretDown
                      style={{ marginTop: 0, marginBottom: 0 }}
                      size="100%"
                    />
                  </div>
                </div>
              </th>
              <th className={styles.headItem}>
                <div className={styles.headerCellContent}>
                  <p className={styles.name2}>Last activity</p>
                  <div className={styles.caretOutline}>
                    <RxCaretDown
                      style={{ marginTop: 0, marginBottom: 0 }}
                      size="100%"
                    />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((user: any, index: number) => {
              return (
                <tr className={styles.bodyRow}>
                  <td className={styles.tdName}>
                    <div className={styles.headerCellContent}>
                      <CustomCheckBox
                        state={checkboxState}
                        index={index + 2}
                        setState={setCheckboxState}
                      />
                      <p
                        className={styles.firstLast}
                      >{`${user.firstName} ${user.lastName}`}</p>
                    </div>
                  </td>
                  <td className={styles.tdNormal}>{user.email}</td>
                  <td className={styles.tdNormal}>{user.role}</td>
                  <td className={styles.tdNormalGroups}>
                    {user.groups.join(", ")}
                  </td>
                  <td className={styles.tdNormal}>
                    {user.lastActive === null
                      ? "N/A"
                      : moment(user.lastActive).format("MMMM DD")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          totalItems={users?.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Users;

const monthMap: { [key: string]: string } = {
  Jan: "january",
  Feb: "february",
  Mar: "march",
  Apr: "april",
  May: "may",
  Jun: "june",
  Jul: "july",
  Aug: "august",
  Sep: "september",
  Oct: "october",
  Nov: "november",
  Dec: "december",
};

export function expandMonthName(abbreviatedMonth: string): string {
  return monthMap[abbreviatedMonth] || "";
}

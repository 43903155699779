// import styles from './styles/styles.module.css'
import styles from "./styles/styles.module.css";
import dropdownStyles from "./styles/dropdownStyles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setBase64Image,
  setEmail,
  setEmailError,
  setFirstName,
  setFirstNameError,
  setGroups,
  setGroupsError,
  setLastName,
  setLastNameError,
  setSelectedFile,
} from "../../Redux/slices/AddUserDataSlice";
import validateFile from "./utils/validateFile";
import convertToBase64 from "./utils/converttoBase64";
import correlateDomain from "./utils/correlateDomain";
import createDomainRegex from "./utils/domainRegex";
import { ReactComponent as GreenCheckMark } from "../../assets/greenCheckmark.svg";
import { ReactComponent as ErrorExclaim } from "../../assets/errorExclaim.svg";
import handleDragOver from "./utils/handleDragOver";
import { ReactComponent as UploadLogo } from "../../assets/uploadLogo.svg";
import { ReactComponent as Caret } from "../../assets/caret.svg";

import { ReactComponent as TrashSymbol } from "../../assets/trashSymbol.svg";
import Select from "react-select";
import { selectElError } from "./styles/selectElError";
import { selectElStyle } from "./styles/selectEl";

export const EmployeeUser = () => {
  const dispatch = useDispatch();

  const { companyDomains, companyGroups } = useSelector(
    (state: any) => state.user,
  );
  const domainRegex = createDomainRegex(companyDomains);
  const {
    email,
    emailError,
    firstName,
    firstNameError,
    groups,
    groupsError,
    lastName,
    lastNameError,
    selectedFile,
  } = useSelector((state: any) => state.newUserData);
  const emailCorrelation = correlateDomain(email, domainRegex, companyDomains);

  const handleGroupSelection = (selected: any) => {
    dispatch(setGroups(selected));
    dispatch(setGroupsError(""));
  };

  function returnIcon() {
    switch (emailCorrelation.match) {
      case null || false:
        return null;
      case "NO":
        return <div className={styles.xSymbol}>❌</div>;
      case true:
        return <GreenCheckMark />;
    }
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (file && validateFile(file)) {
      dispatch(setSelectedFile(file));
      convertToBase64(file, setBase64Image, dispatch);
    } else {
      alert("Please upload a valid JPEG file.");
    }
  };

  //returns the text in the domain field
  function returnDomainName() {
    switch (emailCorrelation.match) {
      case null || false:
        return null;
      case "NO":
        return (
          <p className={styles.correlationDomainName}>
            {emailCorrelation.domain}
          </p>
        );
      case true:
        return (
          <p className={styles.correlationDomainName}>
            {emailCorrelation.domain}
          </p>
        );
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file && validateFile(file)) {
      // Dispatch the file to the Redux store
      dispatch(setSelectedFile(file));

      // Convert the file to Base64 and dispatch to the store
      convertToBase64(file, setBase64Image, dispatch);
    } else {
      alert("Please upload a valid JPEG file.");
    }

    // Reset the input value to ensure the same file can be selected again
    event.target.value = "";
  };

  const handleFileReset = () => {
    dispatch(setSelectedFile(null));
    dispatch(setBase64Image(null));
  };

  function handleFirstNameInput(e: any) {
    dispatch(setFirstNameError(""));
    dispatch(setFirstName(e.target.value));
  }

  function handleEmailInput(e: any) {
    dispatch(setEmailError(""));
    dispatch(setEmail(e.target.value));
  }

  function handleLastNameInput(e: any) {
    dispatch(setLastNameError(""));
    dispatch(setLastName(e.target.value));
  }

  return (
    <div className={styles.leftContainer}>
      <div className={styles.firstNameSide}>
        <div
          style={{ marginBottom: firstNameError ? "7.5px" : "22px" }}
          className={styles.inputContainer}
        >
          <p className={styles.label}>First Name:</p>
          <input
            value={firstName}
            onChange={(e) => handleFirstNameInput(e)}
            className={
              firstNameError ? styles.inputFieldError : styles.inputField
            }
            type="text"
          />
          {firstNameError && (
            <div className={styles.errorContainer}>
              <ErrorExclaim />
              <p className={styles.errorMessage}>{firstNameError}</p>
            </div>
          )}
        </div>

        <div
          style={{ marginBottom: emailError ? "7.5px" : "22px" }}
          className={styles.inputContainer}
        >
          <p className={styles.label}>Email:</p>
          <input
            value={email}
            onChange={(e) => handleEmailInput(e)}
            className={emailError ? styles.inputFieldError : styles.inputField}
            type="email"
          />
          {emailError && (
            <div className={styles.errorContainer}>
              <ErrorExclaim />
              <p className={styles.errorMessage}>{emailError}</p>
            </div>
          )}
        </div>

        <div className={styles.inputContainer}>
          <p className={styles.label}>Profile image:</p>
          <div
            className={styles.uploadContainer}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
          >
            <div
              className={styles.selectButton}
              onClick={() => document.getElementById("fileInput")!.click()}
            >
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept="image/jpeg,image/jpg,image/png"
              />
              <UploadLogo />
              <p className={styles.selectFile}>Select file</p>
            </div>
            <div className={styles.dropFileArea}>
              <p className={styles.dropFileText}>
                {selectedFile ? selectedFile.name : "Drop file here..."}
              </p>
              {selectedFile && (
                <TrashSymbol
                  onClick={handleFileReset}
                  className={styles.trashSymbol}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.lastNameSide}>
        <div
          style={{ marginBottom: lastNameError ? "7.5px" : "22px" }}
          className={styles.inputContainer}
        >
          <p className={styles.label}>Last Name:</p>
          <input
            value={lastName}
            onChange={(e) => handleLastNameInput(e)}
            className={
              lastNameError ? styles.inputFieldError : styles.inputField
            }
            type="text"
          />
          {lastNameError && (
            <div className={styles.errorContainer}>
              <ErrorExclaim />
              <p className={styles.errorMessage}>{lastNameError}</p>
            </div>
          )}
        </div>

        <div className={styles.inputContainer}>
          <p className={styles.label}>Domain:</p>
          <div className={dropdownStyles.dropdownContainer}>
            <div className={styles.inputFieldMock}>
              {returnIcon()}
              {returnDomainName()}
            </div>
          </div>
        </div>

        <div className={styles.inputContainer}>
          <p className={styles.label}>Group:</p>
          <div className={dropdownStyles.dropdownContainer}>
            <Caret className={dropdownStyles.caretIcon} />
            <Select
              options={companyGroups}
              isMulti
              onChange={handleGroupSelection}
              styles={groupsError ? selectElError : selectElStyle}
              value={groups}
              placeholder="Select Groups"
              classNamePrefix="custom"
            />
            {groupsError && (
              <div className={styles.groupErrorContainer}>
                <ErrorExclaim />
                <p className={styles.errorMessage}>{groupsError}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeUser;

/* eslint-disable */

import { selectElStyle } from "./styles/selectEl";
import styles from "./styles/styles.module.css";
import dropdownStyles from "./styles/dropdownStyles.module.css";
import Select from "react-select";
import { ReactComponent as Caret } from "../../assets/caret.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setSystemUser } from "../../Redux/slices/SystemUserSelectionSlice";

const SystemUserSelection = ({ users, setUsers }: any) => {
  const dispatch = useDispatch();
  const { companyUsers, email } = useSelector((state: any) => state.user);

  const { name, profilePic } = useSelector(
    (state: any) => state.selectedSystemUser,
  );

  const selectedEmail = useSelector(
    (state: any) => state.selectedSystemUser.email,
  );

  useEffect(() => {
    if (companyUsers) {
      const mutatedCompanyUsers = companyUsers.filter(
        (usr: any) => !usr.systemUser,
      );
      const usrs = mutatedCompanyUsers.map((usr: any) => {
        return {
          profilePic: usr.profilePictureUrl,
          email: usr.email,
          value: `${usr.firstName} ${usr.lastName}`,
          label: `${usr.firstName} ${usr.lastName}`,
        };
      });
      let mutated = usrs.filter(
        (usr: any) => usr.email.toLowerCase() !== email.toLowerCase(),
      );

      setUsers(mutated);
    }
  }, [companyUsers, email]);

  function handleUserSelection(e: any) {
    dispatch(
      setSystemUser({
        profilePic: e.profilePic,
        email: e.email,
        name: e.value,
      }),
    );
  }

  return (
    <div className={styles.leftContainer}>
      <div className={styles.systemUserTopLeft}>
        <div className={styles.inputContainer}>
          <p className={styles.label}>Select user from employees list:</p>
          <div className={dropdownStyles.dropdownContainer}>
            <Caret className={dropdownStyles.caretIcon} />
            <Select
              options={users}
              onChange={handleUserSelection}
              styles={selectElStyle}
              value={{ value: name, label: name }}
              placeholder="Select a user"
              classNamePrefix="custom"
            />
          </div>
        </div>
        <div className={styles.profileUnderDropdown}>
          <div className={styles.smallProfilePicContainer}>
            {profilePic ? (
              <img alt="profile" className={styles.bioPicSM} src={profilePic} />
            ) : null}
          </div>

          <div className={styles.userData}>
            <p className={styles.selectionName}>{name}</p>
            <p className={styles.selectionEmail}>{selectedEmail}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemUserSelection;

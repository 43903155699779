function searchUsers(searchTerm: string, users: any[]) {
  const lowerSearchTerm = searchTerm.toLowerCase();

  return users.filter((user: any) => {
    const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
    return (
      user.email.toLowerCase().includes(lowerSearchTerm) ||
      user.firstName.toLowerCase().includes(lowerSearchTerm) ||
      user.lastName.toLowerCase().includes(lowerSearchTerm) ||
      fullName.includes(lowerSearchTerm)
    );
  });
}

export default searchUsers;

import React from "react";
import styles from "./styles/styles.module.css";

const EmailDisplay: React.FC<any> = () => {
  return (
    <div className={styles.wrapper}>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ut lacus
        nisi. Phasellus viverra sapien vel velit tincidunt, ac ultrices lectus
        suscipit. Nunc sit amet nunc nec erat varius dictum. Integer consequat
        vestibulum elit, nec vestibulum est tempor non. Aliquam vehicula sapien
        id magna iaculis, non consequat justo dignissim. Suspendisse potenti.
        Nam cursus placerat quam, in facilisis eros tristique nec. Pellentesque
        in risus id libero convallis suscipit in vitae lectus. Donec viverra
        suscipit massa sit amet dapibus. Phasellus fermentum risus sit amet
        lacus vestibulum, a scelerisque libero viverra. In hac habitasse platea
        dictumst. Integer dictum quam vel nisi consectetur laoreet. Quisque
        lobortis suscipit tincidunt. Aliquam erat volutpat. Ut tempor fermentum
        dui, vel aliquam lorem faucibus et. Etiam consequat auctor mi vel
        consectetur. Vivamus aliquam mi ut ex viverra fringilla. Suspendisse
        tempus sollicitudin lectus, a varius arcu faucibus nec. Nullam pharetra
        vehicula velit, quis gravida elit. Morbi pretium convallis magna, non
        tempor erat egestas ac. Suspendisse vestibulum erat et risus faucibus,
        id tincidunt lectus fermentum. Nulla malesuada condimentum sapien, ac
        suscipit mauris vestibulum eget. Nam eget libero quis neque egestas
        iaculis sit amet in sapien. Nullam interdum orci nec velit finibus
        accumsan. Integer pulvinar bibendum sem a bibendum. Nullam bibendum
        purus turpis, nec congue ligula volutpat in. Nulla cursus libero ut orci
        sodales dapibus. Mauris gravida sapien ut volutpat volutpat. Vivamus
        facilisis justo lectus, ac aliquet mi rhoncus at. Sed tincidunt purus at
        lectus vehicula, nec ornare lacus condimentum. Proin dictum metus nec
        libero ultricies ultricies.
      </p>
    </div>
  );
};

export default EmailDisplay;

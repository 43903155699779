import moment from "moment";

export default function showPastSixMonths() {
  // Get the current month as a moment object
  let currentMonth = moment();

  // Initialize an array to hold the month names
  let months = [];

  // Loop to get the past six months, including the current month
  for (let i = 0; i < 6; i++) {
    // Add the abbreviated month name to the array
    months.unshift(currentMonth.format("MMM"));
    // Move to the previous month
    currentMonth = currentMonth.subtract(1, "months");
  }

  return months;
}

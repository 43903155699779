export function truncateInvoiceName(name: string, maxLength = 23) {
  if (name.length <= maxLength) {
    return name;
  }

  const lastDotIndex = name.lastIndexOf(".");
  let extension = "";

  if (lastDotIndex !== -1 && lastDotIndex < name.length - 1) {
    extension = name.substring(lastDotIndex);
  }

  const charsToKeep = maxLength - extension.length - 3; // 3 for '...'

  if (charsToKeep <= 0) {
    return `...${extension}`;
  }

  const truncatedName = name.substring(0, charsToKeep);
  return `${truncatedName}...${extension}`;
}

function parsePathname(pathname: string): string[] {
  // Step 1: Remove the leading slash if present
  const trimmedPathname = pathname.startsWith("/")
    ? pathname.substring(1)
    : pathname;

  // Step 2: Split the pathname into parts
  const parts = trimmedPathname.split("/");

  // Step 3: Capitalize the first letter of each part
  const formattedParts = parts.map((part) => {
    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
  });

  // Step 4: Add "Home" at the beginning if not already present
  if (formattedParts[0] !== "Home") {
    formattedParts.unshift("Home");
  }

  return formattedParts;
}

export default parsePathname;

import React from "react";
import styles from "./styles/styles.module.css";
import { ReactComponent as EmptyPicIcon } from "../../assets/emptyProfilePic.svg";
import moment from "moment";

interface NoteInterface {
  messageIndex: number;
  by: string;
  message: string | JSX.Element;
  date: Date;
}

export const Note: React.FC<NoteInterface> = ({
  by,
  messageIndex,
  message,
  date,
}) => {
  return (
    <div className={messageIndex === 0 ? styles.wrapperFirst : styles.wrapper}>
      <div className={styles.headlineContainer}>
        <EmptyPicIcon />
        <p className={styles.name}>{by}</p>
      </div>
      <div className={styles.text}>{message}</div>
      <p className={styles.date}>{moment(date).format("M/D/YYYY, h:mma")}</p>
    </div>
  );
};

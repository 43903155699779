import React from "react";
import styles from "./styles/styles.module.css";
import ConnectedDot from "../ConnectedDot/ConnectedDot";
import moment from "moment";

interface LogsInterface {
  firstMessage: boolean;
  redDot: boolean;
  action: string;
  actionTime: Date;
  updatedBy: string | null;
}

const MessageContainerLogSteps: React.FC<LogsInterface> = ({
  actionTime,
  action,
  firstMessage,
  redDot,
  updatedBy,
}) => {
  return (
    <div className={firstMessage ? styles.firstMessageWrapper : styles.wrapper}>
      <div className={styles.connectedDotsContainer}>
        <ConnectedDot firstDot={firstMessage} redDot={redDot} />
      </div>

      <div
        className={
          firstMessage ? styles.textContainerFirst : styles.textContainer
        }
      >
        <div className={styles.action1}>
          <p className={styles.action}>{`${action}:`}</p>
          <p className={styles.dateFormat}>
            {moment(actionTime).format("M/D/YYYY, h:mma")}
          </p>
        </div>
        {!firstMessage && (
          <div className={styles.action2}>
            <p className={styles.action}>By:</p>
            <p className={styles.dateFormat}>{updatedBy}</p>
          </div>
        )}

        <div className={styles.dividerLine} />
      </div>
    </div>
  );
};

export default MessageContainerLogSteps;

// src/features/auth/authSlice.ts
import { createSlice } from "@reduxjs/toolkit";

interface SystemUserState {
  email: string;
  name: string;
  profilePic: string;
}

const initialState: SystemUserState = {
  email: "",
  name: "",
  profilePic: "",
};

const systemUserSlice = createSlice({
  name: "System User",
  initialState,
  reducers: {
    setSystemUser(state, action) {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.profilePic = action.payload.profilePic;
    },
  },
});

export const { setSystemUser } = systemUserSlice.actions;

export default systemUserSlice.reducer;

export const mockEmailData = [
  {
    messages: [
      {
        by: "Michael Braun",
        message: "This is message one!!!",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 5),
      },
      {
        by: "Joe Sprinfield",
        message: "Note number 2 for this email!!!",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4),
      },
      {
        by: "Jane Furlong",
        message: "Bypassed this email for some reasons.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4),
      },
      {
        by: "John Blake",
        message: "This email was reviewed and cleared.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4),
      },
    ],
    logs: [
      {
        type: "Received",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30),
        redDot: false,
        by: "System",
      },
      {
        type: "Reviewed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 29),
        redDot: false,
        by: "Sarah Grey",
      },
      {
        type: "Bypassed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 28),
        redDot: false,
        by: "System",
      },
      {
        type: "Escalated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 27),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Resolved",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 25),
        redDot: true,
        by: "System",
      },
      {
        type: "Archived",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 24),
        redDot: true,
        by: "System",
      },
      {
        type: "Follow-up Sent",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 23),
        redDot: false,
        by: "Support Team",
      },
      {
        type: "Note Added",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 22),
        redDot: false,
        by: "Tom Red",
      },
      {
        type: "Flagged for Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 21),
        redDot: true,
        by: "System",
      },
      {
        type: "System Check",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 20),
        redDot: false,
        by: "Automated System",
      },
      {
        type: "Priority Updated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 19),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Customer Response",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 18),
        redDot: false,
        by: "Tom Red",
      },
      {
        type: "Final Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 17),
        redDot: false,
        by: "Senior Analyst",
      },
      {
        type: "Closure Confirmed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 16),
        redDot: true,
        by: "System",
      },
      {
        type: "Archived",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 15),
        redDot: true,
        by: "System",
      },
    ],
    recipientEmails: {
      to: ["user1@recipient.com"],
      cc: ["ccuser1@cced.com", "ccuser2@cced.com", "ccuser3@cced.com"],
      bcc: ["bccuser1@bcced.com", "bccuser2@bcced.com", "bccuser3@bcced.com"],
    },
    invoices: [
      { invoiceName: "invoice1_323.pdf", data: null },
      { invoiceName: "invoice2_888.pdf", data: null },
      { invoiceName: "datasheet3_929_998838_939349.pdf", data: null },
    ],
    senderEmail: "sender1@cygrade.com",
    emailNumber: "#000001",
    resolutionStatus: "RESOLVED",
    severity: "LOW",
    subject: "RE: Your order has shipped",
    senderName: "Derrick Thompson",
    numberOfEmails: 2,
    date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30),
  },
  {
    messages: [
      {
        by: "Emily Johnson",
        message: "Received a request for a refund.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 9),
      },
      {
        by: "Manager Lee",
        message: "Escalated the issue to the billing department.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
      },
      {
        by: "Support Team",
        message: "Follow-up sent regarding the refund status.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 6),
      },
    ],
    logs: [
      {
        type: "Received",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 10),
        redDot: false,
        by: "System",
      },
      {
        type: "Reviewed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 9),
        redDot: false,
        by: "Emily Johnson",
      },
      {
        type: "Pending Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 8),
        redDot: true,
        by: "System",
      },
      {
        type: "Escalated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Follow-up Sent",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 6),
        redDot: false,
        by: "Emily Johnson",
      },
      {
        type: "Flagged for Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 5),
        redDot: true,
        by: "System",
      },
      {
        type: "Note Added",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4),
        redDot: false,
        by: "Emily Johnson",
      },
      {
        type: "System Check",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3),
        redDot: false,
        by: "Automated System",
      },
      {
        type: "Priority Updated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Customer Response",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
        redDot: false,
        by: "Emily Johnson",
      },
    ],
    recipientEmails: {
      to: ["user4@recipient.com", "user5@recipient.com", "user6@recipient.com"],
      cc: ["ccuser4@cced.com", "ccuser5@cced.com", "ccuser6@cced.com"],
      bcc: ["bccuser4@bcced.com", "bccuser5@bcced.com", "bccuser6@bcced.com"],
    },
    invoices: [{ invoiceName: "invoice3_789.pdf", data: null }],
    senderEmail: "sender2@cygrade.com",
    emailNumber: "#000002",
    resolutionStatus: "PENDING",
    severity: "MEDIUM",
    subject: "RE: Request for refund",
    senderName: "Emily Johnson",
    numberOfEmails: 5,
    date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 10),
  },
  {
    messages: [
      {
        by: "John Smith",
        message: "Subscription cancellation request noted.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 6),
      },
      {
        by: "Manager Lee",
        message: "Escalated for further action.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4),
      },
      {
        by: "System",
        message: "Email was resolved automatically.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3),
      },
    ],
    logs: [
      {
        type: "Received",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
        redDot: false,
        by: "System",
      },
      {
        type: "Reviewed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 6),
        redDot: false,
        by: "John Smith",
      },
      {
        type: "Bypassed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 5),
        redDot: false,
        by: "System",
      },
      {
        type: "Escalated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Resolved",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3),
        redDot: true,
        by: "System",
      },
      {
        type: "Archived",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2),
        redDot: true,
        by: "System",
      },
      {
        type: "Follow-up Sent",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
        redDot: false,
        by: "John Smith",
      },
    ],
    recipientEmails: {
      to: ["user7@recipient.com", "user8@recipient.com", "user9@recipient.com"],
      cc: ["ccuser7@cced.com", "ccuser8@cced.com", "ccuser9@cced.com"],
      bcc: ["bccuser7@bcced.com", "bccuser8@bcced.com", "bccuser9@bcced.com"],
    },
    invoices: [
      { invoiceName: "invoice4_456.pdf", data: null },
      { invoiceName: "invoice5_112.pdf", data: null },
    ],
    senderEmail: "sender3@cygrade.com",
    emailNumber: "#000003",
    resolutionStatus: "BYPASSED",
    severity: "SEVERE",
    subject: "RE: Subscription cancellation",
    senderName: "John Smith",
    numberOfEmails: 3,
    date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
  },
  {
    messages: [
      {
        by: "Alice Brown",
        message: "Follow-up on account activation sent.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3),
      },
      {
        by: "System",
        message: "Resolved automatically.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24),
      },
    ],
    logs: [
      {
        type: "Received",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3),
        redDot: false,
        by: "System",
      },
      {
        type: "Reviewed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2),
        redDot: false,
        by: "Alice Brown",
      },
      {
        type: "Resolved",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24),
        redDot: true,
        by: "System",
      },
      { type: "Archived", date: new Date(), redDot: true, by: "System" },
    ],
    recipientEmails: {
      to: [
        "user10@recipient.com",
        "user11@recipient.com",
        "user12@recipient.com",
      ],
      cc: ["ccuser10@cced.com", "ccuser11@cced.com", "ccuser12@cced.com"],
      bcc: [
        "bccuser10@bcced.com",
        "bccuser11@bcced.com",
        "bccuser12@bcced.com",
      ],
    },
    invoices: [{ invoiceName: "invoice6_921.pdf", data: null }],
    senderEmail: "sender4@cygrade.com",
    emailNumber: "#000004",
    resolutionStatus: "NONE",
    severity: "LOW",
    subject: "RE: Follow-up on account activation",
    senderName: "Alice Brown",
    numberOfEmails: 4,
    date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3),
  },
  {
    messages: [
      {
        by: "Michael Green",
        message: "Reported security breach.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 12),
      },
      {
        by: "Manager Lee",
        message: "Escalated for review.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 9),
      },
    ],
    logs: [
      {
        type: "Received",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 12),
        redDot: false,
        by: "System",
      },
      {
        type: "Reviewed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 11),
        redDot: false,
        by: "Michael Green",
      },
      {
        type: "Resolved",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 10),
        redDot: true,
        by: "System",
      },
      {
        type: "Escalated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 9),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Follow-up Sent",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 8),
        redDot: false,
        by: "Michael Green",
      },
      {
        type: "Flagged for Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
        redDot: true,
        by: "System",
      },
      {
        type: "Note Added",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 6),
        redDot: false,
        by: "Michael Green",
      },
      {
        type: "System Check",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 5),
        redDot: false,
        by: "Automated System",
      },
      {
        type: "Priority Updated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Customer Response",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3),
        redDot: false,
        by: "Michael Green",
      },
      {
        type: "Final Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2),
        redDot: false,
        by: "Senior Analyst",
      },
      {
        type: "Closure Confirmed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
        redDot: true,
        by: "System",
      },
      { type: "Archived", date: new Date(), redDot: true, by: "System" },
    ],
    recipientEmails: {
      to: [
        "user13@recipient.com",
        "user14@recipient.com",
        "user15@recipient.com",
      ],
      cc: ["ccuser13@cced.com", "ccuser14@cced.com", "ccuser15@cced.com"],
      bcc: [
        "bccuser13@bcced.com",
        "bccuser14@bcced.com",
        "bccuser15@bcced.com",
      ],
    },
    invoices: [
      { invoiceName: "invoice7_342.pdf", data: null },
      { invoiceName: "invoice8_763.pdf", data: null },
    ],
    senderEmail: "sender5@cygrade.com",
    emailNumber: "#000005",
    resolutionStatus: "NONE",
    severity: "LOW",
    subject: "RE: Security breach report",
    senderName: "Michael Green",
    numberOfEmails: 6,
    date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 12),
  },
  {
    messages: [
      {
        by: "Jessica White",
        message: "Resolved payment overdue notice.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 12 * 1),
      },
    ],
    logs: [
      {
        type: "Received",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
        redDot: false,
        by: "System",
      },
      {
        type: "Reviewed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 12 * 1),
        redDot: false,
        by: "Jessica White",
      },
      { type: "Resolved", date: new Date(), redDot: true, by: "System" },
      {
        type: "Archived",
        date: new Date(new Date().getTime() + 1000 * 60 * 60 * 1),
        redDot: true,
        by: "System",
      },
    ],
    recipientEmails: {
      to: [
        "user16@recipient.com",
        "user17@recipient.com",
        "user18@recipient.com",
      ],
      cc: ["ccuser16@cced.com", "ccuser17@cced.com", "ccuser18@cced.com"],
      bcc: [
        "bccuser16@bcced.com",
        "bccuser17@bcced.com",
        "bccuser18@bcced.com",
      ],
    },
    invoices: [{ invoiceName: "invoice9_532.pdf", data: null }],
    senderEmail: "sender6@cygrade.com",
    emailNumber: "#000006",
    resolutionStatus: "NONE",
    severity: "LOW",
    subject: "RE: Payment overdue notice",
    senderName: "Jessica White",
    numberOfEmails: 1,
    date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
  },
  {
    messages: [
      {
        by: "Samuel Peterson",
        message: "Initial contact made with the client.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
      },
      {
        by: "Laura West",
        message: "Followed up on the inquiry regarding services.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 5),
      },
      {
        by: "System",
        message: "Client responded with additional questions.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3),
      },
      {
        by: "Laura West",
        message: "Provided detailed response and next steps.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
      },
    ],
    logs: [
      {
        type: "Received",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 15),
        redDot: false,
        by: "System",
      },
      {
        type: "Reviewed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14),
        redDot: false,
        by: "Chris Black",
      },
      {
        type: "Pending Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 13),
        redDot: true,
        by: "System",
      },
      {
        type: "Escalated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 12),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Follow-up Sent",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 11),
        redDot: false,
        by: "Chris Black",
      },
      {
        type: "Flagged for Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 10),
        redDot: true,
        by: "System",
      },
      {
        type: "Note Added",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 9),
        redDot: false,
        by: "Chris Black",
      },
      {
        type: "System Check",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 8),
        redDot: false,
        by: "Automated System",
      },
      {
        type: "Priority Updated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Customer Response",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 6),
        redDot: false,
        by: "Chris Black",
      },
      {
        type: "Final Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 5),
        redDot: false,
        by: "Senior Analyst",
      },
      {
        type: "Closure Confirmed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4),
        redDot: true,
        by: "System",
      },
      {
        type: "Archived",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3),
        redDot: true,
        by: "System",
      },
      {
        type: "Feedback Requested",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2),
        redDot: false,
        by: "Chris Black",
      },
      {
        type: "System Update",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
        redDot: false,
        by: "Automated System",
      },
    ],
    recipientEmails: {
      to: [
        "user19@recipient.com",
        "user20@recipient.com",
        "user21@recipient.com",
      ],
      cc: ["ccuser19@cced.com", "ccuser20@cced.com", "ccuser21@cced.com"],
      bcc: [
        "bccuser19@bcced.com",
        "bccuser20@bcced.com",
        "bccuser21@bcced.com",
      ],
    },
    invoices: [
      { invoiceName: "invoice10_124.pdf", data: null },
      { invoiceName: "invoice11_643.pdf", data: null },
    ],
    senderEmail: "sender7@cygrade.com",
    emailNumber: "#000007",
    resolutionStatus: "PENDING",
    severity: "MEDIUM",
    subject: "RE: Legal inquiry",
    senderName: "Chris Black",
    numberOfEmails: 8,
    date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 15),
  },
  {
    messages: [
      {
        by: "David Martin",
        message: "Issue reported by client regarding billing.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 10),
      },
      {
        by: "System",
        message: "Automatic acknowledgment sent.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 9),
      },
      {
        by: "Sarah Brown",
        message: "Investigated the billing issue.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
      },
      {
        by: "System",
        message: "Issue resolved, and confirmation sent to client.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 5),
      },
    ],
    logs: [
      {
        type: "Received",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 20),
        redDot: false,
        by: "System",
      },
      {
        type: "Reviewed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 19),
        redDot: false,
        by: "Sophia Blue",
      },
      {
        type: "Pending",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 18),
        redDot: true,
        by: "System",
      },
      {
        type: "Escalated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 17),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Follow-up Sent",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 16),
        redDot: false,
        by: "Sophia Blue",
      },
      {
        type: "Flagged for Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 15),
        redDot: true,
        by: "System",
      },
      {
        type: "Note Added",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14),
        redDot: false,
        by: "Sophia Blue",
      },
      {
        type: "System Check",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 13),
        redDot: false,
        by: "Automated System",
      },
      {
        type: "Priority Updated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 12),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Customer Response",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 11),
        redDot: false,
        by: "Sophia Blue",
      },
      {
        type: "Final Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 10),
        redDot: false,
        by: "Senior Analyst",
      },
      {
        type: "Closure Confirmed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 9),
        redDot: true,
        by: "System",
      },
      {
        type: "Archived",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 8),
        redDot: true,
        by: "System",
      },
      {
        type: "Feedback Requested",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
        redDot: false,
        by: "Sophia Blue",
      },
      {
        type: "System Update",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 6),
        redDot: false,
        by: "Automated System",
      },
    ],
    recipientEmails: {
      to: [
        "user22@recipient.com",
        "user23@recipient.com",
        "user24@recipient.com",
      ],
      cc: ["ccuser22@cced.com", "ccuser23@cced.com", "ccuser24@cced.com"],
      bcc: [
        "bccuser22@bcced.com",
        "bccuser23@bcced.com",
        "bccuser24@bcced.com",
      ],
    },
    invoices: [{ invoiceName: "invoice12_998.pdf", data: null }],
    senderEmail: "sender8@cygrade.com",
    emailNumber: "#000008",
    resolutionStatus: "PENDING",
    severity: "MEDIUM",
    subject: "RE: Product feedback request",
    senderName: "Sophia Blue",
    numberOfEmails: 2,
    date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 20),
  },
  {
    messages: [
      {
        by: "Alex Johnson",
        message: "Meeting scheduled with the client for a demo.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14),
      },
      {
        by: "Client Support",
        message: "Client requested additional information on features.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 12),
      },
      {
        by: "Alex Johnson",
        message: "Provided client with the requested documentation.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 10),
      },
      {
        by: "System",
        message: "Demo completed successfully.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 8),
      },
    ],
    logs: [
      {
        type: "Received",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 25),
        redDot: false,
        by: "System",
      },
      {
        type: "Reviewed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 24),
        redDot: false,
        by: "Tom Red",
      },
      {
        type: "Resolved",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 23),
        redDot: true,
        by: "System",
      },
      {
        type: "Escalated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 22),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Follow-up Sent",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 21),
        redDot: false,
        by: "Tom Red",
      },
      {
        type: "Flagged for Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 20),
        redDot: true,
        by: "System",
      },
      {
        type: "Note Added",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 19),
        redDot: false,
        by: "Tom Red",
      },
      {
        type: "System Check",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 18),
        redDot: false,
        by: "Automated System",
      },
      {
        type: "Priority Updated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 17),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Customer Response",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 16),
        redDot: false,
        by: "Tom Red",
      },
      {
        type: "Final Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 15),
        redDot: false,
        by: "Senior Analyst",
      },
      {
        type: "Closure Confirmed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14),
        redDot: true,
        by: "System",
      },
      {
        type: "Archived",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 13),
        redDot: true,
        by: "System",
      },
      {
        type: "Feedback Requested",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 12),
        redDot: false,
        by: "Tom Red",
      },
      {
        type: "System Update",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 11),
        redDot: false,
        by: "Automated System",
      },
    ],
    recipientEmails: {
      to: [
        "user25@recipient.com",
        "user26@recipient.com",
        "user27@recipient.com",
      ],
      cc: ["ccuser25@cced.com", "ccuser26@cced.com", "ccuser27@cced.com"],
      bcc: [
        "bccuser25@bcced.com",
        "bccuser26@bcced.com",
        "bccuser27@bcced.com",
      ],
    },
    invoices: [
      { invoiceName: "invoice13_312.pdf", data: null },
      { invoiceName: "invoice14_788.pdf", data: null },
    ],
    senderEmail: "sender9@cygrade.com",
    emailNumber: "#000009",
    resolutionStatus: "RESOLVED",
    severity: "LOW",
    subject: "RE: Bug report",
    senderName: "Tom Red",
    numberOfEmails: 9,
    date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 25),
  },
  {
    messages: [
      {
        by: "Nina Clark",
        message: "Client requested an update on the project timeline.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 15),
      },
      {
        by: "Project Manager",
        message: "Reviewed project progress and noted some delays.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 13),
      },
      {
        by: "Nina Clark",
        message: "Communicated the delays to the client with revised timeline.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 12),
      },
      {
        by: "System",
        message:
          "Client acknowledged the revised timeline and requested further updates.",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 10),
      },
    ],
    logs: [
      {
        type: "Received",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30),
        redDot: false,
        by: "System",
      },
      {
        type: "Reviewed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 29),
        redDot: false,
        by: "Sarah Grey",
      },
      {
        type: "Bypassed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 28),
        redDot: false,
        by: "System",
      },
      {
        type: "Escalated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 27),
        redDot: false,
        by: "Manager Lee",
      },
      {
        type: "Resolved",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 26),
        redDot: true,
        by: "System",
      },
      {
        type: "Archived",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 25),
        redDot: true,
        by: "System",
      },
      {
        type: "Follow-up Sent",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 24),
        redDot: false,
        by: "Support Team",
      },
      {
        type: "Note Added",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 23),
        redDot: false,
        by: "Sarah Grey",
      },
      {
        type: "Flagged for Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 22),
        redDot: true,
        by: "System",
      },
      {
        type: "System Check",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 21),
        redDot: false,
        by: "Automated System",
      },
      {
        type: "Priority Updated",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 20),
        redDot: true,
        by: "Manager Lee",
      },
      {
        type: "Customer Response",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 19),
        redDot: false,
        by: "Sarah Grey",
      },
      {
        type: "Final Review",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 18),
        redDot: false,
        by: "Senior Analyst",
      },
      {
        type: "Closure Confirmed",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 17),
        redDot: true,
        by: "System",
      },
      {
        type: "Archived",
        date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 16),
        redDot: true,
        by: "System",
      },
    ],
    recipientEmails: {
      to: [
        "user28@recipient.com",
        "user29@recipient.com",
        "user30@recipient.com",
      ],
      cc: ["ccuser28@cced.com", "ccuser29@cced.com", "ccuser30@cced.com"],
      bcc: [
        "bccuser28@bcced.com",
        "bccuser29@bcced.com",
        "bccuser30@bcced.com",
      ],
    },
    invoices: [{ invoiceName: "invoice15_711.pdf", data: null }],
    senderEmail: "sender10@cygrade.com",
    emailNumber: "#000010",
    resolutionStatus: "BYPASSED",
    severity: "SEVERE",
    subject: "RE: Account suspension",
    senderName: "Sarah Grey",
    numberOfEmails: 7,
    date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30),
  },
];

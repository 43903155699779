import React, { useState } from "react";
import styles from "./styles/styles.module.css";
import { ReactComponent as EmptyProfileIcon } from "../../assets/ProfileEmpty.svg";
import { ReactComponent as CaretDownFilled } from "../../assets/CarretFilledDown.svg";
import { ReactComponent as ThreeDots } from "../../assets/threeDots.svg";
import { ReactComponent as Paperclip } from "../../assets/paperclip.svg";
import EmailDisplay from "../EmailDisplay/EmailDisplay";
import MessageApproval from "../MessageApproval/MessageApproval";
import { emailResolutionTypes } from "../../types/emailTypes";
import Customdropdown from "../CustomDropdown/CustomDropdown";
import MessageContainerLogs from "../MessageContainerLogs/MessageContainerLogs";
import NotesContainer from "../Notes/NotesContainer";

interface MessageContainerInterface {
  selectedIndex: number;
  senderName: string;
  senderEmail: string;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  recipientEmails: any;
  messageApprovalState: string;
  invoices: any[];
  logs: any[];
  messages: any[];
}

const MessageContainer: React.FC<MessageContainerInterface> = ({
  selectedIndex,
  setSelectedIndex,
  senderName,
  senderEmail,
  recipientEmails,
  messageApprovalState,
  invoices,
  logs,
  messages,
}) => {
  const [attachmentDropdownOpen, setAttachmentDropdownOpen] = useState(false);
  const [emailDropdownOpen, setEmailDropdownOpen] = useState(false);

  function handleTabClick(tabIndex: number) {
    setSelectedIndex(tabIndex);
  }

  function returnMessageApprovalState() {
    if (messageApprovalState === emailResolutionTypes.FLAGGED) {
      return {
        approvalComponent: <MessageApproval approval={messageApprovalState} />,
        divider: <div className={styles.shortDivider} />,
      };
    } else if (messageApprovalState === emailResolutionTypes.PENDING) {
      return {
        approvalComponent: <MessageApproval approval={messageApprovalState} />,
        divider: <div className={styles.shortDivider} />,
      };
    } else if (messageApprovalState === emailResolutionTypes.BYPASSED) {
      return {
        approvalComponent: <MessageApproval approval={messageApprovalState} />,
        divider: <div className={styles.shortDivider} />,
      };
    } else {
      return null;
    }
  }

  <div className={styles.attachmentContainer}>
    <Paperclip className={styles.paperclip} />
    <p className={styles.attachmentsNumber}>No Attachments</p>
  </div>;

  function returnAttachmentMessage() {
    if (invoices.length === 0) {
      return (
        <div className={styles.attachmentContainer}>
          <Paperclip className={styles.paperclip} />
          <p className={styles.attachmentsNumber}>No Attachments</p>
        </div>
      );
    } else if (invoices.length === 1) {
      return (
        <div className={styles.attachmentContainer}>
          <Paperclip className={styles.paperclip} />
          <p className={styles.fileName}>{invoices[0].invoiceName}</p>
        </div>
      );
    } else if (invoices.length === 2) {
      return (
        <div className={styles.attachmentContainer}>
          <Paperclip className={styles.paperclip} />
          <p className={styles.fileName}>{invoices[0].invoiceName}</p>
          <p
            className={styles.attachmentsNumber}
          >{`(+${invoices.length - 1} attachment)`}</p>
          <CaretDownFilled
            onClick={() => setAttachmentDropdownOpen(!attachmentDropdownOpen)}
            className={styles.attachmentCaret}
          />
          <Customdropdown
            to={null}
            cc={null}
            bcc={null}
            open={attachmentDropdownOpen}
            dropdownType="ATTACHMENT"
            invoiceData={invoices}
          />
        </div>
      );
    } else if (invoices.length > 2) {
      return (
        <div className={styles.attachmentContainer}>
          <Paperclip className={styles.paperclip} />
          <p className={styles.fileName}>{invoices[0].invoiceName}</p>
          <p
            className={styles.attachmentsNumber}
          >{`(+${invoices.length - 1} attachments)`}</p>
          <CaretDownFilled
            onClick={() => setAttachmentDropdownOpen(!attachmentDropdownOpen)}
            className={styles.attachmentCaret}
          />
          <Customdropdown
            to={null}
            cc={null}
            bcc={null}
            open={attachmentDropdownOpen}
            dropdownType="ATTACHMENT"
            invoiceData={invoices}
          />
        </div>
      );
    }
  }

  function renderBody() {
    if (selectedIndex === 0) {
      return (
        <>
          <div className={styles.senderHeader}>
            <div className={styles.profileImageContainer}>
              <EmptyProfileIcon className={styles.profileImage} />
            </div>

            <div className={styles.senderAndRecipient}>
              <div className={styles.sendernameInfo}>
                <p className={styles.senderName}>{senderName}</p>
                <p className={styles.senderEmail}>{`(${senderEmail})`}</p>
                <ThreeDots className={styles.threeDots} />
              </div>
              <div className={styles.recipientInfo}>
                <p
                  className={styles.recipientName}
                >{`TO: ${recipientEmails.to[0]}`}</p>
                <p
                  className={styles.bulkRecipients}
                >{`, +${recipientEmails?.bcc?.length + recipientEmails?.cc?.length} `}</p>
                <CaretDownFilled
                  onClick={() => setEmailDropdownOpen(!emailDropdownOpen)}
                  className={styles.caretFilled}
                />
                <Customdropdown
                  to={recipientEmails.to}
                  cc={recipientEmails.cc}
                  bcc={recipientEmails.bcc}
                  open={emailDropdownOpen}
                  dropdownType="ALL_RECIPIENTS"
                  invoiceData={invoices}
                />
              </div>
            </div>
          </div>
          <div className={styles.shortDivider} />
          <div className={styles.emailHeaderContainer}>
            <div>
              <p className={styles.accountUpdateHeadline}>Account Update</p>
              <p className={styles.emailDate}>Tue, 6/4/2024, 3:55pm</p>
            </div>
            <button className={styles.viewEmailButton}>View email</button>
          </div>
          <div className={styles.emailTextContainer}>
            <EmailDisplay />
            {returnAttachmentMessage()}
            {returnMessageApprovalState()?.divider}
            {returnMessageApprovalState()?.approvalComponent}
          </div>
        </>
      );
    } else if (selectedIndex === 1) {
      return (
        <>
          <MessageContainerLogs logData={logs} />
          {returnMessageApprovalState()?.divider}
          {returnMessageApprovalState()?.approvalComponent}
        </>
      );
    } else if (selectedIndex === 2) {
      return (
        <>
          <NotesContainer messages={messages} />
          {returnMessageApprovalState()?.divider}
          {returnMessageApprovalState()?.approvalComponent}
        </>
      );
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.topContainer}>
        <div
          onClick={() => handleTabClick(0)}
          className={selectedIndex === 0 ? styles.tabSelected : styles.tab}
        >
          Message
        </div>
        <div
          onClick={() => handleTabClick(1)}
          className={selectedIndex === 1 ? styles.tabSelected : styles.tab}
        >
          Logs
        </div>
        <div
          onClick={() => handleTabClick(2)}
          className={selectedIndex === 2 ? styles.tabSelected : styles.tab}
        >{`Notes (1)`}</div>
      </div>
      <div className={styles.divider} />
      {renderBody()}
    </div>
  );
};

export default MessageContainer;

function correlateDomain(
  email: string,
  domainRegex: RegExp,
  companyDomains: any[],
) {
  const match = email.match(domainRegex);
  const inputDomain = email.split("@")[1];
  if (match) {
    const matchedDomain = match[1]; // This gives the domain that matched in the regex
    const correlated = companyDomains.find(
      (item: any) => item.value === matchedDomain,
    );
    return correlated
      ? { match: true, domain: inputDomain }
      : { match: "NO", domain: inputDomain };
  }
  return !inputDomain
    ? { match: false, domain: "" }
    : { match: "NO", domain: inputDomain };
}

export default correlateDomain;

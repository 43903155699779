import { FC } from "react";
import styles from "./styles/styles.module.css";
import MenuItem from "../MenuItem/MenuItem";
import { useSelector } from "react-redux";

import { ReactComponent as CygradeLogo } from "../../assets/LogoWhite.svg";
import { ReactComponent as NameIcon } from "../../assets/sidebarNameIcon.svg";
import { ReactComponent as SettingDots } from "../../assets/settingDots.svg";
//Menu item logos
import { ReactComponent as BurgerSymbol } from "../../assets/BurgerSymbol.svg";
import { ReactComponent as Lightning } from "../../assets/Lightning.svg";
import { ReactComponent as Profile } from "../../assets/profilePerson.svg";
import { ReactComponent as Reporting } from "../../assets/Reporting.svg";
import { ReactComponent as Case } from "../../assets/Case.svg";
import { ReactComponent as Settings } from "../../assets/Settings.svg";
import { ReactComponent as Shield } from "../../assets/Shield.svg";
import { ReactComponent as Octagon } from "../../assets/Octagon.svg";
import ExpandableMenuItem from "../ExpandableMenuItem/ExpandableMenuItem";

const SideBar: FC = () => {
  const { firstName, lastName, company } = useSelector(
    (state: any) => state.user,
  );
  const subItems = [
    { name: "Active Triggers", route: "/activeTriggers" },
    { name: "Add New", route: "/addNew" },
  ];
  const subItems2 = [
    { name: "Sub Item 1", route: "/subOne" },
    { name: "Sub Item 2", route: "/subTwo" },
    { name: "Sub Item 3", route: "/subThree" },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <CygradeLogo className={styles.logo} />
      </div>
      <div className={styles.nameContainer}>
        <div className={styles.nameSubContainer}>
          <NameIcon className={styles.nameIcon} />
          <div className={styles.nameAndCompany}>
            <p className={styles.mainName}>{`${firstName} ${lastName}`}</p>
            <p className={styles.companyName}>{`${company}`}</p>
          </div>
          <SettingDots className={styles.settingDots} />
        </div>
      </div>
      <div className={styles.menuItemsContainer}>
        <MenuItem
          Logo={BurgerSymbol}
          itemName="Dashboard"
          active={true}
          width="14.73px"
          height="9.82"
          route="/dashboard"
          breadcrumbs={["Home"]}
        />
        <MenuItem
          Logo={Lightning}
          itemName="Events"
          active={false}
          width="14"
          height="17"
          route="/events"
          breadcrumbs={["Events", "Test"]}
        />
        <MenuItem
          Logo={Profile}
          itemName="Users"
          active={false}
          width="18"
          height="14.73"
          route="/users"
          breadcrumbs={["Home", "Users"]}
        />
        <MenuItem
          Logo={Reporting}
          itemName="Reporting"
          active={false}
          width="16"
          height="16"
          route="/reporting"
          breadcrumbs={["Reporting", "Test", "Another Test"]}
        />
        <MenuItem
          Logo={Case}
          itemName="Case Management"
          active={false}
          width="18"
          height="16.2"
          route="/users"
          breadcrumbs={["Case Management"]}
        />
        <MenuItem
          Logo={Settings}
          itemName="Settings"
          active={false}
          width="18"
          height="18"
          route="/settings"
          breadcrumbs={["Settings", "Add User"]}
        />
      </div>
      <div className={styles.monitoringContainer}>
        <ExpandableMenuItem
          Logo={Shield}
          name="Monitoring"
          subItems={subItems}
          color="#9C59DF"
        />
        <ExpandableMenuItem
          Logo={Octagon}
          name="Active Protection"
          subItems={subItems2}
          color="#14C477"
        />
      </div>
      <div className={styles.bottomContainer}>
        <p
          style={{ marginBottom: "6px" }}
          className={styles.bottomContainerText}
        >
          Resources
        </p>
        <p className={styles.bottomContainerText}>Contact</p>
      </div>
    </div>
  );
};

export default SideBar;

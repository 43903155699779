import React, { useState } from "react";
import styles from "./styles/styles.module.css";
import { ReactComponent as ArrowLeft } from "../../assets/PaginationLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/PaginationRight.svg";

interface PaginationTypes {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: any;
}

const Pagination: React.FC<PaginationTypes> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const [expanded, setExpanded] = useState(false);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  function pageDown() {
    if (currentPage !== 1) {
      setExpanded(true);
      setCurrentPage(currentPage - 1);
    }
  }

  function pageUp() {
    if (currentPage !== pageNumbers.length) {
      setExpanded(true);
      setCurrentPage(currentPage + 1);
    }
  }

  function buttonWidthProcessor(number: number) {
    const num = number.toString();
    if (num.length === 3) {
      return {
        active: styles.btnActiveLarge,
        inactive: styles.btnLarge,
      };
    } else if (num.length === 4) {
      return {
        active: styles.btnActiveXLarge,
        inactive: styles.btnXLarge,
      };
    } else {
      return {
        active: styles.btnActive,
        inactive: styles.btn,
      };
    }
  }

  return (
    <nav>
      <div className={styles.wrapper}>
        <ArrowLeft onClick={() => pageDown()} className={styles.arrowLeft} />
        {expanded ? (
          pageNumbers.map((number: number) => (
            <button
              onClick={() => handleClick(number)}
              className={
                currentPage !== number
                  ? buttonWidthProcessor(number)?.inactive
                  : buttonWidthProcessor(number)?.inactive
              }
            >
              {number}
            </button>
          ))
        ) : (
          <>
            <button
              onClick={() => handleClick(1)}
              className={
                currentPage !== 1
                  ? buttonWidthProcessor(1)?.inactive
                  : buttonWidthProcessor(1)?.active
              }
            >
              1
            </button>

            <button onClick={() => setExpanded(true)} className={styles.btn}>
              ...
            </button>

            <button
              onClick={() => handleClick(pageNumbers.length)}
              className={
                currentPage !== pageNumbers.length
                  ? buttonWidthProcessor(pageNumbers.length)?.inactive
                  : buttonWidthProcessor(pageNumbers.length)?.active
              }
            >
              {pageNumbers.length}
            </button>
          </>
        )}
        <ArrowRight onClick={() => pageUp()} />
      </div>
    </nav>
  );
};

export default Pagination;

import React from "react";
import styles from "./styles/styles.module.css";
import { ReactComponent as Paperclip } from "../../assets/paperclip.svg";
import { truncateInvoiceName } from "./utils/truncateInvoiceName";

interface CustomDropdownInterface {
  dropdownType: string;
  invoiceData: any[] | null;
  open: boolean;
  to: any[] | null;
  cc: any[] | null;
  bcc: any[] | null;
}

const Customdropdown: React.FC<CustomDropdownInterface> = ({
  to,
  cc,
  bcc,
  dropdownType,
  invoiceData,
  open,
}) => {
  function returnRecipients(recipientKey: any[]) {
    if (recipientKey.length > 2) {
      return (
        <>
          <p className={styles.email}>{recipientKey[0]}</p>
          <p
            className={styles.email}
          >{`${recipientKey[1]}, +${recipientKey.length - 2}`}</p>
        </>
      );
    } else if (recipientKey.length === 2) {
      return (
        <>
          <p className={styles.email}>{recipientKey[0]}</p>
          <p className={styles.email}>{recipientKey[1]}</p>
        </>
      );
    } else if (recipientKey.length < 2) {
      return <p className={styles.email}>{recipientKey[0]}</p>;
    } else {
      return null;
    }
  }

  function renderDropdown() {
    if (dropdownType === "ATTACHMENT") {
      return (
        <div style={{ width: "248px" }} className={styles.wrapper}>
          {invoiceData?.map(({ invoiceName }) => {
            return (
              <div className={styles.attachmentContainer}>
                <Paperclip className={styles.paperClip} />
                <p className={styles.invoiceName}>
                  {truncateInvoiceName(invoiceName)}
                </p>
              </div>
            );
          })}
        </div>
      );
    } else if (dropdownType === "ALL_RECIPIENTS") {
      return (
        <div style={{ width: "290px" }} className={styles.wrapperRecipient}>
          <div className={styles.recipientWrapper}>
            <div className={styles.recipientData}>
              <div className={styles.toContainer}>
                <p className={styles.to}>To:</p>
              </div>
              <div className={styles.recipientEmails}>
                {to && returnRecipients(to)}
              </div>
            </div>

            <div className={styles.recipientData}>
              <div className={styles.toContainer}>
                <p className={styles.to}>CC:</p>
              </div>
              <div className={styles.recipientEmails}>
                {cc && returnRecipients(cc)}
              </div>
            </div>

            <div className={styles.recipientData}>
              <div className={styles.toContainer}>
                <p className={styles.to}>BCC:</p>
              </div>
              <div className={styles.recipientEmails}>
                {bcc && returnRecipients(bcc)}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div style={{ width: "248px" }} className={styles.wrapper}></div>;
    }
  }

  return open ? renderDropdown() : null;
};

export default Customdropdown;

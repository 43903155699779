export enum emailSeverityTypes {
  SEVERE = "SEVERE",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export enum emailResolutionTypes {
  RESOLVED = "RESOLVED",
  BYPASSED = "BYPASSED",
  PENDING = "PENDING",
  FLAGGED = "FLAGGED",
  NONE = "NONE",
}

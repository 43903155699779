import React from "react";
import styles from "./styles/styles.module.css";
import { emailResolutionTypes } from "../../types/emailTypes";

interface EmailBadgeInterface {
  status: emailResolutionTypes;
  visible: boolean;
}

const EmailBadge: React.FC<EmailBadgeInterface> = ({ visible, status }) => {
  function returnBadgeStyling() {
    switch (status) {
      case emailResolutionTypes.FLAGGED:
        return {
          badgeColor: {
            border: "1px solid #E43935",
            background:
              "linear-gradient(0deg, rgba(228, 57, 53, 0.10) 0%, rgba(228, 57, 53, 0.10) 100%), #FFF",
          },
          textColor: { color: "#E43935" },
          text: "Flagged",
        };
      case emailResolutionTypes.BYPASSED:
        return {
          badgeColor: {
            border: "1px solid #E43935",
            background:
              "linear-gradient(0deg, rgba(228, 57, 53, 0.10) 0%, rgba(228, 57, 53, 0.10) 100%), #FFF",
          },
          textColor: { color: "#E43935" },
          text: "Bypassed",
        };
      case emailResolutionTypes.PENDING:
        return {
          badgeColor: {
            border: "1px solid #FA901B",
            background:
              "linear-gradient(0deg, rgba(250, 144, 27, 0.10) 0%, rgba(250, 144, 27, 0.10) 100%), #FFF",
          },
          textColor: { color: "#FA901B" },
          text: "Pending",
        };

      case emailResolutionTypes.RESOLVED:
        return {
          badgeColor: {
            border: "1px solid #14C477",
            background:
              "linear-gradient(0deg, rgba(20, 196, 119, 0.10) 0%, rgba(20, 196, 119, 0.10) 100%), #FFF",
          },
          textColor: { color: "#14C477" },
          text: "Resolved",
        };
    }
  }

  return (
    <div
      style={returnBadgeStyling()?.badgeColor}
      className={visible ? styles.wrapper : styles.wrapperHidden}
    >
      <p style={returnBadgeStyling()?.textColor} className={styles.badgeText}>
        {returnBadgeStyling()?.text}
      </p>
    </div>
  );
};

export default EmailBadge;

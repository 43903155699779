import React, { ReactNode, FC } from "react";
import styles from "./styles/styles.module.css";
import SideBar from "../components/SideBar/SideBar";
import ContentsectionHeader from "../components/Header/ContentSectionHeader";
import { useLocation } from "react-router-dom";

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();
  console.log(location.pathname);
  return (
    <div className={styles.wrapper}>
      <SideBar />
      <div className={styles.contentContainer}>
        <ContentsectionHeader />
        <div className={styles.contentDisplay}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;

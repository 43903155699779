import styles from "./styles/styles.module.css";
import dropdownStyles from "./styles/dropdownStyles.module.css";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ReactComponent as Caret } from "../../assets/caret.svg";
import { selectElError } from "./styles/selectElError";
import { selectElStyle } from "./styles/selectEl";
import {
  setAccessPermission1,
  setAccessPermission2,
  setAccessPermission3,
  setAccessPermission4,
  setAccessPermission5,
  setRole,
  setRoleError,
} from "../../Redux/slices/AddUserDataSlice";
import { ReactComponent as ErrorExclaim } from "../../assets/errorExclaim.svg";
import { ReactComponent as CheckMark } from "../../assets/checkMark.svg";

const PermissionFields = () => {
  const dispatch = useDispatch();
  const {
    accessPermission1,
    accessPermission2,
    accessPermission3,
    accessPermission4,
    accessPermission5,
    role,
    roleError,
  } = useSelector((state: any) => state.newUserData);
  const { companyRoles } = useSelector((state: any) => state.user);

  const handleRoleSelection = (selection: any) => {
    dispatch(setRole(selection));
    dispatch(setRoleError(""));
  };

  return (
    <>
      <p className={styles.userPermissionsHeadline}>Permissions:</p>
      <div className={styles.containerGroup2}>
        <div className={styles.bottomLeftContainer}>
          <div
            style={{ marginBottom: roleError ? "7.5px" : "22px" }}
            className={styles.inputContainer}
          >
            <p className={styles.label}>Role:</p>
            <div className={dropdownStyles.dropdownContainer}>
              <Caret
                style={roleError ? { top: "37%" } : { top: "50%" }}
                className={dropdownStyles.caretIcon}
              />
              <Select
                options={companyRoles}
                onChange={handleRoleSelection}
                styles={roleError ? selectElError : selectElStyle}
                value={role}
                placeholder="Select Role"
                classNamePrefix="custom"
              />
              {roleError && (
                <div className={styles.errorContainer}>
                  <ErrorExclaim />
                  <p className={styles.errorMessage}>{roleError}</p>
                </div>
              )}
            </div>
          </div>

          <div className={styles.checkboxWrapper}>
            <div className={styles.checkboxContainer}>
              <div
                onClick={() =>
                  dispatch(setAccessPermission1(!accessPermission1))
                }
                className={styles.checkbox}
              >
                {accessPermission1 && <CheckMark />}
              </div>
              <p className={styles.checkboxText}>Has access to add users</p>
            </div>
            <div className={styles.checkboxContainer}>
              <div
                onClick={() =>
                  dispatch(setAccessPermission2(!accessPermission2))
                }
                className={styles.checkbox}
              >
                {accessPermission2 && <CheckMark />}
              </div>
              <p className={styles.checkboxText}>
                Has access to review all emails
              </p>
            </div>
            <div className={styles.checkboxContainer}>
              <div
                onClick={() =>
                  dispatch(setAccessPermission3(!accessPermission3))
                }
                className={styles.checkbox}
              >
                {accessPermission3 && <CheckMark />}
              </div>
              <p className={styles.checkboxText}>
                Other access permissions here
              </p>
            </div>
            <div className={styles.checkboxContainer}>
              <div
                onClick={() =>
                  dispatch(setAccessPermission4(!accessPermission4))
                }
                className={styles.checkbox}
              >
                {accessPermission4 && <CheckMark />}
              </div>
              <p className={styles.checkboxText}>Other access permissions</p>
            </div>
            <div className={styles.checkboxContainer}>
              <div
                onClick={() =>
                  dispatch(setAccessPermission5(!accessPermission5))
                }
                className={styles.checkbox}
              >
                {accessPermission5 && <CheckMark />}
              </div>
              <p className={styles.checkboxText}>One more permission setting</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionFields;

// src/features/auth/authSlice.ts
import { createSlice } from "@reduxjs/toolkit";

interface SliderTypes {
  currentSelection: string;
  currentPosition: string;
}

const initialState: SliderTypes = {
  currentSelection: "System Users",
  currentPosition: "Left",
};

const sliderSlice = createSlice({
  name: "Slider",
  initialState,
  reducers: {
    setSlider(state, action) {
      state.currentSelection = action.payload.currentSelection;
      state.currentPosition = action.payload.currentPosition;
    },
  },
});

export const { setSlider } = sliderSlice.actions;

export default sliderSlice.reducer;

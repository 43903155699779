// src/features/auth/authSlice.ts
import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  active: boolean;
  email: string;
  tokenExpiration: any;
  profilePic: string;
  firstName: string;
  lastName: string;
  role: string;
  company: string;
  incidents: incidentsTypes;
  flaggedEmails: flaggedEmailsTypes;
  companyUsers: any[];
  companyDomains: any[];
  companyRoles: any[];
  companyGroups: any[];
  add_new_emails: boolean;
  review_all_emails: boolean;
  permission_3: boolean;
  permission_4: boolean;
  permission_5: boolean;
}

interface flaggedEmailsTypes {
  data_year: string;
  number_of_data_loss_prevention: number;
  number_of_flagged_emails: number;
  number_of_malicious_incidents: number;
  number_of_other_incidents: number;
  number_of_total_emails: number;
  percentage_of_data_loss_prevention: number;
  percentage_of_flagged_emails: number;
  percentage_of_malicious_incidents: number;
  percentage_of_other_incidents: number;
}

interface incidentsTypes {
  data_year: string;
  january: number;
  february: number;
  march: number;
  april: number;
  may: number;
  june: number;
  july: number;
  august: number;
  september: number;
  october: number;
  november: number;
  december: number;
  number_of_incidents: number;
}

const initialState: AuthState = {
  active: false,
  email: "",
  tokenExpiration: null,
  profilePic: "",
  firstName: "",
  lastName: "",
  role: "",
  company: "",
  companyUsers: [],
  companyDomains: [],
  companyRoles: [],
  companyGroups: [],
  add_new_emails: false,
  review_all_emails: false,
  permission_3: false,
  permission_4: false,
  permission_5: false,
  incidents: {
    data_year: "",
    january: 0,
    february: 0,
    march: 0,
    april: 0,
    may: 0,
    june: 0,
    july: 0,
    august: 0,
    september: 0,
    october: 0,
    november: 0,
    december: 0,
    number_of_incidents: 0,
  },
  flaggedEmails: {
    data_year: "",
    number_of_data_loss_prevention: 0,
    number_of_flagged_emails: 0,
    number_of_malicious_incidents: 0,
    number_of_other_incidents: 0,
    number_of_total_emails: 0,
    percentage_of_data_loss_prevention: 0,
    percentage_of_flagged_emails: 0,
    percentage_of_malicious_incidents: 0,
    percentage_of_other_incidents: 0,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.active = action.payload.active;
      state.email = action.payload.email;
      state.tokenExpiration = action.payload.tokenExpiration;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.role = action.payload.role;
      state.company = action.payload.company;
      state.profilePic = action.payload.profilePic;

      // Handle incidents data
      state.incidents.data_year = action.payload.incidents.data_year;
      state.incidents.january = action.payload.incidents.january;
      state.incidents.february = action.payload.incidents.february;
      state.incidents.march = action.payload.incidents.march;
      state.incidents.april = action.payload.incidents.april;
      state.incidents.may = action.payload.incidents.may;
      state.incidents.june = action.payload.incidents.june;
      state.incidents.july = action.payload.incidents.july;
      state.incidents.august = action.payload.incidents.august;
      state.incidents.september = action.payload.incidents.september;
      state.incidents.october = action.payload.incidents.october;
      state.incidents.november = action.payload.incidents.november;
      state.incidents.december = action.payload.incidents.december;
      state.incidents.number_of_incidents =
        action.payload.incidents.number_of_incidents;

      // Handle flaggedEmails data
      state.flaggedEmails.data_year = action.payload.flaggedEmails.data_year;
      state.flaggedEmails.number_of_data_loss_prevention =
        action.payload.flaggedEmails.number_of_data_loss_prevention;
      state.flaggedEmails.number_of_flagged_emails =
        action.payload.flaggedEmails.number_of_flagged_emails;
      state.flaggedEmails.number_of_malicious_incidents =
        action.payload.flaggedEmails.number_of_malicious_incidents;
      state.flaggedEmails.number_of_other_incidents =
        action.payload.flaggedEmails.number_of_other_incidents;
      state.flaggedEmails.number_of_total_emails =
        action.payload.flaggedEmails.number_of_total_emails;
      state.flaggedEmails.percentage_of_data_loss_prevention =
        action.payload.flaggedEmails.percentage_of_data_loss_prevention;
      state.flaggedEmails.percentage_of_flagged_emails =
        action.payload.flaggedEmails.percentage_of_flagged_emails;
      state.flaggedEmails.percentage_of_malicious_incidents =
        action.payload.flaggedEmails.percentage_of_malicious_incidents;
      state.flaggedEmails.percentage_of_other_incidents =
        action.payload.flaggedEmails.percentage_of_other_incidents;

      //adding company users
      state.companyUsers = action.payload.companyUsers;

      state.companyDomains = action.payload.companyDomains;
      state.companyRoles = action.payload.companyRoles;
      state.companyGroups = action.payload.companyGroups;

      state.add_new_emails = action.payload.add_new_emails;
      state.review_all_emails = action.payload.review_all_emails;
      state.permission_3 = action.payload.permission_3;
      state.permission_4 = action.payload.permission_4;
      state.permission_5 = action.payload.permission_5;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;

import { StylesConfig } from "react-select";

export const selectElStyle: StylesConfig<any> = {
  control: (provided) => ({
    ...provided,
    boxSizing: "border-box",
    height: "40px",
    width: "334px",
    maxWidth: "334px",
    minWidth: "334px",
    color: "#818181",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    borderRadius: "5px",
    border: "1px solid #e8e8e8",
    backgroundColor: "#fff",
    paddingLeft: "11px",
    paddingRight: "30px", // Space for the custom caret
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0",
  }),
  dropdownIndicator: () => ({
    display: "none", // Hide the default dropdown arrow
  }),
  indicatorSeparator: () => ({
    display: "none", // Remove the vertical line next to the caret
  }),
};

import styles from "./styles/styles.module.css";
import { setSlider } from "../../Redux/slices/SliderSlice";
import { useDispatch, useSelector } from "react-redux";

interface handleClickInterface {
  value: string;
  position: string;
}

export const EmployeeTypeSwitch = () => {
  const dispatch = useDispatch();
  const { currentSelection, currentPosition } = useSelector(
    (state: any) => state.slider,
  );

  function handleClick(positionData: handleClickInterface) {
    dispatch(
      setSlider({
        currentSelection: positionData.value,
        currentPosition: positionData.position,
      }),
    );
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={
          currentPosition === "Left" ? styles.sliderLeft : styles.sliderRight
        }
      >
        {currentSelection}
      </div>
      <div
        onClick={() => handleClick({ value: "System Users", position: "Left" })}
        className={styles.leftContainer}
      >
        System Users
      </div>
      <div
        onClick={() => handleClick({ value: "Employees", position: "Right" })}
        className={styles.rightContainer}
      >
        Employees
      </div>
    </div>
  );
};

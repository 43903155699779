import React, { FC } from "react";
import styles from "./styles/styles.module.css";
import LineChart from "../LineChart/LineChart";
import BarChart from "../BarChart/BarChart";
import PieChart from "../PieChart/PieChart";
import { useSelector } from "react-redux";

interface ChartInterface {
  headline: string;
  numericData: number;
  percentage: number | null;
  chartType: "LINE" | "BAR" | "PIE";
}

const DashboardChartCanvas: FC<ChartInterface> = ({
  headline,
  numericData,
  percentage,
  chartType,
}) => {
  const formattedNumber = new Intl.NumberFormat("en-US").format(numericData);
  const { flaggedEmails } = useSelector((state: any) => state.user);
  function returnChart() {
    switch (chartType) {
      case "LINE":
        return <LineChart />;
      case "BAR":
        return <BarChart />;
      case "PIE":
        return (
          <div className={styles.pieContainer}>
            <PieChart
              percentage={flaggedEmails.percentage_of_malicious_incidents.toFixed(
                0,
              )}
              label="Malicious incidents"
            />
            <PieChart
              percentage={flaggedEmails.percentage_of_data_loss_prevention.toFixed(
                0,
              )}
              label="Data loss prevention"
            />
            <PieChart
              percentage={flaggedEmails.percentage_of_other_incidents.toFixed(
                0,
              )}
              label="Other"
            />
          </div>
        );
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.typographicData}>
        <p className={styles.headline}>{headline}</p>
        {!percentage ? (
          <p className={styles.numericData}>{formattedNumber}</p>
        ) : (
          <p
            className={styles.numericData}
          >{`${formattedNumber} (${percentage}%)`}</p>
        )}
      </div>
      <div className={styles.chartArea}>{returnChart()}</div>
    </div>
  );
};

export default DashboardChartCanvas;

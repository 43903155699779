/* eslint-disable */

import React, { useEffect, useState } from "react";
import styles from "./styles/styles.module.css";
import { ReactComponent as Caret } from "../../assets/caret.svg";
import { useSelector } from "react-redux";
import searchUsers from "./utils/searchUsers";
import filterByRole from "./utils/filterByRole";
import filterUsersByGroup from "./utils/filterByGroup";

const UsersHeader: React.FC<any> = ({ usersSetter, modifiedUsers }) => {
  const [selectedRole, setSelectedRole] = useState("Employees");
  const [searchValue, setSearchValue] = useState("");
  const [activeFlag, setActiveFlag] = useState("Active");
  const [selectedGroup, setSelectedGroup] = useState("All");
  const { companyUsers } = useSelector((state: any) => state.user);

  function handleInput(e: any) {
    console.log(e.target.value.trim().length);
    setSearchValue(e.target.value);
  }

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(e.target.value);
  };

  const handleGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedGroup(e.target.value);
  };

  const handleActiveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setActiveFlag(e.target.value);
  };

  useEffect(() => {
    // Start with the full list of users
    let filteredUsers = companyUsers;

    // Apply search filter if there's a search value
    if (searchValue.trim().length > 0) {
      filteredUsers = searchUsers(searchValue, filteredUsers);
    }

    // Apply role filter if it's not "Employees"
    if (selectedRole.toLowerCase() !== "employees") {
      filteredUsers = filterByRole(selectedRole, filteredUsers);
    }

    // Apply group filter if it's not "All"
    if (selectedGroup.toLowerCase() !== "all") {
      filteredUsers = filterUsersByGroup(selectedGroup, filteredUsers);
    }

    // Apply active status filter
    const isActive = activeFlag.toLowerCase() === "active";
    filteredUsers = filteredUsers.filter(
      (user: any) => user.active === isActive,
    );

    // Set the filtered users or the full list if no filters are applied
    usersSetter(filteredUsers);
  }, [searchValue, selectedRole, selectedGroup, activeFlag, companyUsers]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.filterContainer}>
        <p className={styles.label}>Search:</p>
        <input
          onChange={handleInput}
          type="text"
          placeholder="Type here..."
          className={styles.inputText}
        />
      </div>
      <div className={styles.filterContainer}>
        <p className={styles.label}>Role:</p>
        <div className={styles.dropdownContainer}>
          <Caret className={styles.caretIcon} />
          <select
            onChange={handleRoleChange}
            value={selectedRole}
            className={styles.selectEl}
          >
            <option>Employees</option>
            <option>Admin</option>
            <option>IT Team</option>
            <option>Support</option>
          </select>
        </div>
      </div>
      <div className={styles.filterContainer}>
        <p className={styles.label}>Group:</p>
        <div className={styles.dropdownContainer}>
          <Caret className={styles.caretIcon} />
          <select
            value={selectedGroup}
            onChange={handleGroupChange}
            className={styles.selectEl}
          >
            <option>All</option>
            <option>Admin</option>
            <option>IT</option>
            <option>Management</option>
            <option>Sales</option>
          </select>
        </div>
      </div>
      <div className={styles.filterContainer}>
        <p className={styles.label}>Status:</p>
        <div className={styles.dropdownContainer}>
          <Caret className={styles.caretIcon} />
          <select
            value={activeFlag}
            onChange={handleActiveChange}
            className={styles.selectEl}
          >
            <option>Active</option>
            <option>Inactive</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default UsersHeader;

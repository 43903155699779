import React, { useRef, useState } from "react";
import styles from "./styles/styles.module.css";
import DOMPurify from "dompurify";
import { ReactComponent as BoldIcon } from "../../assets/format_bold.svg";
import { ReactComponent as ItalicIcon } from "../../assets/ormat_italic.svg";
import { ReactComponent as UnderlinedIcon } from "../../assets/format_underlined.svg";
import { ReactComponent as ListIcon } from "../../assets/format_list_bulleted.svg";
import { ReactComponent as ShareIcon } from "../../assets/insert_link.svg";
import { Note } from "../Note/Note";

interface NotesContainerInterface {
  messages: any[];
}

type FormatterState = {
  BOLD: boolean;
  ITALIC: boolean;
  UNDERLINE: boolean;
  LIST: boolean;
};

const NotesContainer: React.FC<NotesContainerInterface> = ({ messages }) => {
  const [notes, setNotes] = useState(messages);
  const editorRef = useRef<HTMLDivElement>(null);
  const [activeFormatter, setActiveFormatter] = useState<FormatterState>({
    BOLD: false,
    ITALIC: false,
    UNDERLINE: false,
    LIST: false,
  });

  const selectionRangeRef = useRef<Range | null>(null);

  // Save the current selection and update formatting state
  function saveSelection() {
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0) {
      selectionRangeRef.current = sel.getRangeAt(0).cloneRange();
      updateFormatterState(); // Update formatting state
    }
  }

  // Update the formatting state based on the current selection
  function updateFormatterState() {
    const bold = document.queryCommandState("bold");
    const italic = document.queryCommandState("italic");
    const underline = document.queryCommandState("underline");
    const list = document.queryCommandState("insertUnorderedList");

    setActiveFormatter({
      BOLD: bold,
      ITALIC: italic,
      UNDERLINE: underline,
      LIST: list,
    });
  }

  // Apply formatting to the selected text and update state
  function handleActivateFormat(format: keyof FormatterState) {
    // Toggle the formatter state
    setActiveFormatter((prevState) => ({
      ...prevState,
      [format]: !prevState[format],
    }));

    if (editorRef.current) {
      editorRef.current.focus();

      switch (format) {
        case "BOLD":
          document.execCommand("bold", false, "");
          break;
        case "ITALIC":
          document.execCommand("italic", false, "");
          break;
        case "UNDERLINE":
          document.execCommand("underline", false, "");
          break;
        case "LIST":
          document.execCommand("insertUnorderedList", false, "");
          break;
        default:
          break;
      }

      // Update formatting state after applying the format
      updateFormatterState();
    }
  }

  // Handle sending the content and reset the editor
  function handleSend() {
    if (editorRef.current) {
      const content = editorRef.current.innerHTML;
      const sanitizedContent = DOMPurify.sanitize(content);
      const newNote = {
        by: "Current User",
        message: sanitizedContent,
        date: new Date(),
      };
      setNotes([...notes, newNote]);
      editorRef.current.innerHTML = "";

      // Reset format state when content is sent
      setActiveFormatter({
        BOLD: false,
        ITALIC: false,
        UNDERLINE: false,
        LIST: false,
      });
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.messageMainContainer}>
        <div className={styles.messageContentContainer}>
          {notes.map((msg: any, index: number) => {
            return (
              <Note
                key={index}
                date={msg.date}
                by={msg.by}
                messageIndex={index}
                message={
                  <div dangerouslySetInnerHTML={{ __html: msg.message }} />
                }
              />
            );
          })}
        </div>
        <div className={styles.messageControls}>
          <div
            ref={editorRef}
            contentEditable
            className={`${styles.textArea} ${styles.editorContent}`}
            onInput={saveSelection}
            onMouseUp={saveSelection}
            onKeyUp={saveSelection}
            onFocus={saveSelection}
          />
          <div className={styles.formatController}>
            <div className={styles.formatLeft}>
              <BoldIcon
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => handleActivateFormat("BOLD")}
                className={
                  activeFormatter.BOLD
                    ? styles.formatIconActive
                    : styles.formatIcon
                }
              />
              <ItalicIcon
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => handleActivateFormat("ITALIC")}
                className={
                  activeFormatter.ITALIC
                    ? styles.formatIconActive
                    : styles.formatIcon
                }
              />
              <UnderlinedIcon
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => handleActivateFormat("UNDERLINE")}
                className={
                  activeFormatter.UNDERLINE
                    ? styles.formatIconActive
                    : styles.formatIcon
                }
              />
              <ListIcon
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => handleActivateFormat("LIST")}
                className={
                  activeFormatter.LIST
                    ? styles.formatIconActive
                    : styles.formatIcon
                }
              />
              <ShareIcon className={styles.formatIcon} />
            </div>
            <div className={styles.formatRight}>
              <button onClick={handleSend} className={styles.btn}>
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesContainer;

import React from "react";
import styles from "./styles/styles.module.css";
import { ReactComponent as FolderSymbol } from "../../assets/folderSymbol.svg";

const NoEmailSelected: React.FC<any> = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.dataContainer}>
        <FolderSymbol />
        <p className={styles.nothingSelected}>Nothing Selected</p>
        <p className={styles.clickOnMessage}>Click on a message to open it</p>
      </div>
    </div>
  );
};

export default NoEmailSelected;

/* eslint-disable */

import React, { useEffect, useState } from "react";
import styles from "./styles/styles.module.css";
import handleDragOver from "./utils/handleDragOver";
import { ReactComponent as CheckMark } from "../../assets/checkMark.svg";
import { ReactComponent as CloudUploadBig } from "../../assets/cloudUploadBig.svg";
import JSZip from "jszip";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  bulkCreateUser,
  upgradeUser,
  bulkUpgradeUsers,
} from "../../api/user";
import { ReactComponent as TrashSymbol } from "../../assets/trashSymbol.svg";
import createDomainRegex from "./utils/domainRegex";
import { useNavigate } from "react-router-dom";
import correlateDomain from "./utils/correlateDomain";
import setFieldError from "./utils/setFieldError";
import {
  setAccessPermission1,
  setAccessPermission2,
  setAccessPermission3,
  setAccessPermission4,
  setAccessPermission5,
  setBase64Image,
  setEmail,
  setEmailError,
  setFirstName,
  setFirstNameError,
  setGroups,
  setGroupsError,
  setLastName,
  setLastNameError,
  setMultiLoading,
  setRole,
  setRoleError,
  setSelectedFile,
  setSingleLoading,
  setXlsFileChecked,
  setXlsFileError,
  setXlsFiles,
  toggleXlsFileChecked,
} from "../../Redux/slices/AddUserDataSlice";

import EmployeeUser from "./EmployeeUserFields";
import PermissionFields from "./PermissionFields";
import SystemUserSelection from "./SystemUserSelection";

import createUserStateObject from "../../components/utils/createUserStateObject";
import { setUser } from "../../Redux/slices/UserSlice";
import { setSystemUser } from "../../Redux/slices/SystemUserSelectionSlice";

const AddUsers: React.FC = () => {
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const {
    xlsFiles,
    accessPermission1,
    accessPermission2,
    accessPermission3,
    accessPermission4,
    accessPermission5,
    email,

    firstName,

    groups,

    lastName,

    multiLoading,
    role,

    selectedFile,
    singleLoading,
    xlsFileChecked,
  } = useSelector((state: any) => state.newUserData);

  const selectedEmail = useSelector(
    (state: any) => state.selectedSystemUser.email,
  );
  const { currentPosition } = useSelector((state: any) => state.slider);
  const navigate = useNavigate();
  const { companyDomains } = useSelector((state: any) => state.user);
  const domainRegex = createDomainRegex(companyDomains);

  const emailCorrelation = correlateDomain(email, domainRegex, companyDomains);

  useEffect(() => {
    resetForm();
  }, [currentPosition]);

  const handleCheckBoxChange = (index: number) => {
    dispatch(toggleXlsFileChecked(index));
  };

  const resetForm = () => {
    dispatch(setFirstName(""));
    dispatch(setLastName(""));
    dispatch(setEmail(""));
    dispatch(setXlsFileChecked([]));
    dispatch(setXlsFiles([]));
    dispatch(setXlsFileError("No files selected"));
    dispatch(setSingleLoading(false));
    dispatch(setMultiLoading(false));
    dispatch(setRole({ value: null, label: null }));
    dispatch(setGroups([]));
    dispatch(setBase64Image(null));
    dispatch(setGroupsError(""));
    dispatch(setRoleError(""));
    dispatch(setEmailError(""));
    dispatch(setLastNameError(""));
    dispatch(setFirstNameError(""));
    dispatch(setAccessPermission1(false));
    dispatch(setAccessPermission2(false));
    dispatch(setAccessPermission3(false));
    dispatch(setAccessPermission4(false));
    dispatch(setAccessPermission5(false));
    dispatch(setSelectedFile(null));
  };

  const validateFieldsEmployee = () => {
    let isValid = true;
    if (firstName.trim().length <= 1) {
      dispatch(setFirstNameError("First name is required"));
      isValid = false;
    } else {
      dispatch(setFirstNameError(""));
    }

    if (email.trim().length <= 1) {
      dispatch(setEmailError("A valid email address is required"));
      isValid = false;
    } else {
      dispatch(setEmailError(""));
    }

    if (lastName.trim().length <= 1) {
      dispatch(setLastNameError("Last name is required"));
      isValid = false;
    } else {
      dispatch(setLastNameError(""));
    }

    if (!domainRegex.test(email.toLowerCase())) {
      dispatch(setEmailError("Only company email addresses can be used"));
      isValid = false;
    }

    if (email.length < 3) {
      dispatch(setEmailError("You have to provide a valid email"));
      isValid = false;
    }

    if (groups.length === 0) {
      dispatch(setGroupsError("At least one group has to be assigned"));
      isValid = false;
    } else {
      dispatch(setGroupsError(""));
    }
    return isValid;
  };

  const validateFieldsSystemUser = () => {
    let isValid = true;

    //check for selection don't let submit without selection

    if (!role.value) {
      dispatch(setRoleError("A role has to be assigned"));
      isValid = false;
    } else {
      dispatch(setRoleError(""));
    }

    return isValid;
  };

  const submitUser = async (e: any) => {
    e.preventDefault();
    try {
      if (!validateFieldsEmployee()) {
        return;
      }
      dispatch(setSingleLoading(true));
      const formData = new FormData();
      if (selectedFile) {
        formData.append("file", selectedFile);
      }

      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("domain", emailCorrelation.domain || "");
      formData.append("groups", groups.map((gr: any) => gr.value).toString());

      formData.append("permission_add_users", "false");
      formData.append("permission_review_all_emails", "false");
      formData.append("permission1", "false");
      formData.append("permission2", "false");
      formData.append("permission3", "false");

      const res = await createUser(formData);

      if ((res as any).success) {
        const userData = createUserStateObject(res);
        dispatch(setUser(userData));
        dispatch(setSingleLoading(false));
        alert((res as any).msg);
        resetForm();
      } else {
        if ((res as any).msg === "Invalid token.") {
          localStorage.removeItem("cognitoSession");
          sessionStorage.removeItem("cognitoSession");
          alert("Logged out for security, please log in again");
          dispatch(setSingleLoading(false));
          resetForm();
          navigate("/login");
        } else {
          if ((res as any).field) {
            setFieldError((res as any).field, setEmailError);
          }
          dispatch(setSingleLoading(false));
        }
      }
    } catch (err) {
      console.log(err);
      dispatch(setSingleLoading(false));
      alert("An error occurred while uploading the file.");
    }
  };

  const submitSystemUser = async (e: any) => {
    e.preventDefault();
    if (!singleLoading) {
      if (!validateFieldsSystemUser) {
        return;
      }

      if (!role.value) {
        dispatch(setRoleError("A role has to be selected"));
      }
      dispatch(setSingleLoading(true));
      try {
        const payload = {
          email: selectedEmail,
          role: role.value,
          permission_add_users: accessPermission1,
          permission_review_all_emails: accessPermission2,
          extraPermission1: accessPermission3,
          extraPermission2: accessPermission4,
          extraPermission3: accessPermission5,
        };

        const res = await upgradeUser(payload);

        if ((res as any).success) {
          //resets arr state of first dropdown in system users
          setUsers((prevUsers) =>
            prevUsers.filter((user: any) => user.email !== selectedEmail),
          );
          dispatch(
            setSystemUser({
              profilePic: null,
              email: null,
              name: null,
            }),
          );
          //stops loading, shows alert and resets all remaining forms.
          dispatch(setSingleLoading(false));
          alert((res as any).msg);
          resetForm();
        } else {
          if ((res as any).msg === "Invalid token.") {
            localStorage.removeItem("cognitoSession");
            sessionStorage.removeItem("cognitoSession");
            alert("Logged out for security, please log in again");
            dispatch(setSingleLoading(false));
            resetForm();
            navigate("/login");
          } else {
            if ((res as any).field) {
              setFieldError((res as any).field, setEmailError);
            }
            dispatch(setSingleLoading(false));
          }
        }
      } catch (error) {
        dispatch(setSingleLoading(false));
        alert("An error occurred while uploading the file.");
      }
    }
  };

  const handleXlsFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validFiles = Array.from(files).filter(
        (file) => file.name.endsWith(".xls") || file.name.endsWith(".xlsx"),
      );
      if (validFiles.length > 0) {
        dispatch(setXlsFiles([...xlsFiles, ...validFiles]));
        dispatch(setXlsFileError("")); // Clear the error message if the file is valid
      } else {
        dispatch(setXlsFileError("Please upload valid XLS files."));
      }
    }

    // Reset the file input value to allow re-selecting the same file
    event.target.value = "";
  };

  const handleXlsFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      const validFiles = Array.from(files).filter(
        (file) => file.name.endsWith(".xls") || file.name.endsWith(".xlsx"),
      );
      if (validFiles.length > 0) {
        dispatch(setXlsFiles([...xlsFiles, ...validFiles]));
        dispatch(setXlsFileError("")); // Clear the error message if the file is valid
      } else {
        dispatch(setXlsFileError("Please upload valid XLS files."));
      }
    }
  };

  function handleBulkCreate() {
    dispatch(setMultiLoading(true));

    try {
      const zip = new JSZip();

      if (xlsFileChecked.length === 0) {
        return alert("Please select the files you want to upload");
      }

      // Add each XLS file to the zip
      xlsFiles.forEach((file: File, index: number) => {
        if (xlsFileChecked[index]) {
          zip.file(file.name, file);
        }
      });

      // Generate the zip file as a blob
      zip.generateAsync({ type: "blob" }).then(async (zipBlob) => {
        const formData = new FormData();
        formData.append("file", zipBlob, "files.zip");
        let res;

        //if new users are created in bulk
        if (currentPosition === "Right") {
          res = await bulkCreateUser(formData);
          //if users are upgraded in bulk
        } else if (currentPosition === "Left") {
          res = await bulkUpgradeUsers(formData);
        }

        // Send the zipped file to the backend
        if ((res as any).success) {
          dispatch(setXlsFiles([]));
        }
        dispatch(setMultiLoading(false));
        alert((res as any).msg);
      });
    } catch (err) {
      dispatch(setMultiLoading(false));
    }
  }

  function bulkCreateProcessor() {
    if (!multiLoading) {
      return handleBulkCreate();
    } else {
      return;
    }
  }

  function handleXlsDelete(index: number, xlsFiles: any) {
    const filesCopy = xlsFileChecked.slice();
    const mutated = filesCopy.splice(index, 1);
    dispatch(setXlsFileChecked(mutated));
    dispatch(setXlsFiles(xlsFiles.filter((_: any, i: any) => i !== index)));
  }

  return (
    <form
      onSubmit={currentPosition === "Right" ? submitUser : submitSystemUser}
    >
      <div className={styles.wrapper}>
        <h1 className={styles.userDetailsHeadline}>
          {currentPosition === "Left" ? "Add system user:" : "User details:"}
        </h1>

        <div className={styles.containerGroup}>
          {currentPosition === "Right" ? (
            <EmployeeUser />
          ) : (
            <SystemUserSelection users={users} setUsers={setUsers} />
          )}

          <div className={styles.rightContainer}>
            <div className={styles.profileCard}>
              <div className={styles.importUsersHeadlineBox}>
                <p className={styles.importUsersHeadline}>Import Users</p>
              </div>

              <div className={styles.smallBoxHr1}></div>

              <div
                className={styles.bigCloudSymbol}
                onDrop={handleXlsFileDrop}
                onDragOver={handleDragOver}
                onDragEnter={(e) => e.preventDefault()}
                onDragLeave={(e) => e.preventDefault()}
              >
                <CloudUploadBig />
                <p className={styles.dropFilesHere}>
                  {xlsFiles.length > 0 ? "Files added" : "Drop files here..."}
                </p>
              </div>

              <div
                className={styles.cloudUploadBigButton}
                onClick={() => document.getElementById("xlsFileInput")!.click()}
              >
                Select files
              </div>

              <input
                type="file"
                id="xlsFileInput"
                style={{ display: "none" }}
                onChange={handleXlsFileChange}
                accept=".xls,.xlsx"
                multiple
              />

              <div className={styles.smallBoxHr2}></div>

              {xlsFiles.length > 0 ? (
                xlsFiles.map((file: File, index: number) => (
                  <div key={index} className={styles.checkBoxInSmallContainer}>
                    <div
                      onClick={() => handleCheckBoxChange(index)}
                      className={styles.checkbox2}
                    >
                      {xlsFileChecked[index] && <CheckMark />}
                    </div>
                    <p className={styles.xlsFileName}>{file.name}</p>
                    <TrashSymbol
                      className={styles.trashSymbol}
                      onClick={() => handleXlsDelete(index, xlsFiles)}
                    />
                  </div>
                ))
              ) : (
                <p className={styles.xlsFileName2}>No files selected</p>
              )}

              <div className={styles.smallBoxHr3}></div>

              <div
                onClick={bulkCreateProcessor}
                className={styles.importButton}
              >
                {multiLoading ? "Loading..." : "Import"}
              </div>
            </div>
          </div>
        </div>

        {currentPosition === "Right" ? null : <PermissionFields />}

        {currentPosition === "Right" && <div className={styles.finalHr}></div>}

        <button
          style={
            currentPosition === "Right"
              ? { marginTop: "35px" }
              : { marginTop: "47px" }
          }
          disabled={singleLoading}
          type="submit"
          className={styles.createUserButton}
        >
          {singleLoading ? "Loading... " : "Create user"}
        </button>
      </div>
    </form>
  );
};

export default AddUsers;

import React from "react";
import styles from "./styles/styles.module.css";

interface ConnectedDotInterface {
  redDot: boolean;
  firstDot: boolean;
}

const ConnectedDot: React.FC<ConnectedDotInterface> = ({
  redDot,
  firstDot,
}) => {
  return (
    <div className={firstDot ? styles.wrapperFirst : styles.wrapper}>
      <div
        style={
          redDot
            ? { backgroundColor: "#E43935" }
            : { backgroundColor: "#818181" }
        }
        className={firstDot ? styles.firstDot : styles.dot}
      />
      <div
        className={firstDot ? styles.verticalLineFirst : styles.verticalLine}
      />
    </div>
  );
};

export default ConnectedDot;

import React from "react";
import styles from "./styles/styles.module.css";
import { ReactComponent as CheckMark } from "../../assets/checkMark.svg";

const CustomCheckBox: React.FC<any> = ({
  allData,
  checkAll,
  setState,
  index,
  state,
}) => {
  function handleCheckBoxChange() {
    // Toggle individual checkbox state
    setState({ ...state, [index]: !state[index] });

    // If checkAll is true, toggle all checkboxes
    if (checkAll) {
      const allChecked = Object.values(state).every((value) => value === true); // Check if all are currently checked
      const newState: { [key: number]: boolean } = {}; // Create a new state object

      allData.forEach((item: any, idx: number) => {
        newState[idx] = !allChecked; // If all are checked, uncheck; otherwise, check all
      });

      setState(newState); // Update the state with the toggled checkboxes
    }
  }

  return (
    <div onClick={() => handleCheckBoxChange()} className={styles.checkbox}>
      {state[index] && <CheckMark />}
    </div>
  );
};

export default CustomCheckBox;

import React from "react";
import styles from "./styles/styles.module.css";
import {
  emailResolutionTypes,
  emailSeverityTypes,
} from "../../types/emailTypes";
import CustomCheckBox from "../CustomCheckBox/CustomCheckBox";
import EmailBadge from "../EmailBadge/EmailBadge";
import moment from "moment";

interface EmailPreviewInterface {
  index: number;
  selectedIndex: number | null;
  setIndex: React.Dispatch<React.SetStateAction<number | null>>;
  severity: emailSeverityTypes;
  subject: string;
  name: string;
  numberOfEmails: number;
  checkboxState: { [key: number]: boolean };
  setCheckboxState: React.Dispatch<React.SetStateAction<any>>;
  badgeStatus: emailResolutionTypes;
  date: Date;
}

const EmailPreview: React.FC<EmailPreviewInterface> = ({
  setIndex,
  index,
  selectedIndex,
  severity,
  subject,
  name,
  numberOfEmails,
  checkboxState,
  setCheckboxState,
  badgeStatus,
  date,
}) => {
  function returnDividerClass() {
    if (index === 0) {
      if (selectedIndex === index) {
        return {
          top: styles.dividerTopSelected,
          bottom: styles.dividerBottomSelected,
        };
      } else {
        return {
          top: styles.dividerTop,
          bottom: styles.dividerBottom,
        };
      }
    } else if (selectedIndex === 1) {
      if (selectedIndex === index) {
        return {
          top: styles.dividerTopSelected,
          bottom: "",
        };
      } else {
        return {
          top: styles.dividerTopSelected,
          bottom: "",
        };
      }
    } else {
      if (selectedIndex === index) {
        return {
          top: "",
          bottom: styles.dividerBottomSelected,
        };
      } else if (selectedIndex === index + 1) {
        return {
          top: "",
          bottom: styles.dividerBottomSelected,
        };
      } else {
        return {
          top: "",
          bottom: styles.dividerBottom,
        };
      }
    }
  }

  function verticalBarColor() {
    switch (severity) {
      case emailSeverityTypes.SEVERE:
        return "#E43935";
      case emailSeverityTypes.MEDIUM:
        return "#FA901B";
      case emailSeverityTypes.LOW:
        return "#818181";
    }
  }

  return (
    <div
      onClick={() => setIndex(index)}
      style={
        selectedIndex === index
          ? { backgroundColor: "#F4F8FB", width: "calc(100% + 1px)" }
          : { backgroundColor: "#FFF" }
      }
      className={styles.wrapper}
    >
      <div
        style={{ backgroundColor: verticalBarColor() }}
        className={styles.marker}
      />
      <div className={styles.content}>
        <div className={returnDividerClass()?.top} />

        <div className={styles.dataContainer}>
          <CustomCheckBox
            allData={[]}
            checkAll={false}
            index={index}
            state={checkboxState}
            setState={setCheckboxState}
          />
          <div className={styles.textContainer}>
            <p className={styles.name}>{name}</p>
            <p className={styles.subject}>{`${subject} (${numberOfEmails})`}</p>
          </div>

          <div
            className={
              selectedIndex === index
                ? styles.dateAndBadgeAlingFix
                : styles.dateAndBadge
            }
          >
            <p className={styles.date}>{moment(date).format("MMMM DD")}</p>
            <EmailBadge
              visible={(badgeStatus as any) !== "NONE"}
              status={badgeStatus}
            />
          </div>
        </div>

        {selectedIndex !== 1 ? (
          <div className={returnDividerClass()?.bottom} />
        ) : null}
      </div>
    </div>
  );
};

export default EmailPreview;

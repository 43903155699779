// src/features/user/userSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RoleType {
  value: string | null;
  label: string | null;
}

interface UserState {
  firstName: string;
  lastName: string;
  email: string;
  xlsFileChecked: boolean[];
  xlsFiles: File[];
  xlsFileError: string;
  singleLoading: boolean;
  multiLoading: boolean;
  role: RoleType;
  groups: string[];
  base64Image: string | null;
  groupsError: string;
  roleError: string;
  emailError: string;
  lastNameError: string;
  firstNameError: string;
  accessPermission1: boolean;
  accessPermission2: boolean;
  accessPermission3: boolean;
  accessPermission4: boolean;
  accessPermission5: boolean;
  selectedFile: File | null;
}

const initialState: UserState = {
  firstName: "",
  lastName: "",
  email: "",
  xlsFileChecked: [],
  xlsFiles: [],
  xlsFileError: "No files selected",
  singleLoading: false,
  multiLoading: false,
  role: { value: null, label: null },
  groups: [],
  base64Image: null,
  groupsError: "",
  roleError: "",
  emailError: "",
  lastNameError: "",
  firstNameError: "",
  accessPermission1: false,
  accessPermission2: false,
  accessPermission3: false,
  accessPermission4: false,
  accessPermission5: false,
  selectedFile: null,
};

const addUserDataSlice = createSlice({
  name: "user data slice",
  initialState,
  reducers: {
    setFirstName(state, action: PayloadAction<string>) {
      state.firstName = action.payload;
    },
    setLastName(state, action: PayloadAction<string>) {
      state.lastName = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setXlsFileChecked: (state, action: PayloadAction<boolean[]>) => {
      state.xlsFileChecked = action.payload;
    },
    toggleXlsFileChecked: (state, action: PayloadAction<number>) => {
      const index = action.payload;

      // Ensure that the array is large enough to hold the index
      if (state.xlsFileChecked.length <= index) {
        // Initialize any missing indices with false
        state.xlsFileChecked = [
          ...state.xlsFileChecked,
          ...new Array(index - state.xlsFileChecked.length + 1).fill(false),
        ];
      }

      // Toggle the boolean value at the specific index
      state.xlsFileChecked[index] = !state.xlsFileChecked[index];
    },
    setXlsFiles(state, action: PayloadAction<File[]>) {
      state.xlsFiles = action.payload;
    },
    setXlsFileError(state, action: PayloadAction<string>) {
      state.xlsFileError = action.payload;
    },
    setSingleLoading(state, action: PayloadAction<boolean>) {
      state.singleLoading = action.payload;
    },
    setMultiLoading(state, action: PayloadAction<boolean>) {
      state.multiLoading = action.payload;
    },
    setRole(state, action: PayloadAction<RoleType>) {
      state.role = action.payload;
    },
    setGroups(state, action: PayloadAction<string[]>) {
      state.groups = action.payload;
    },
    setBase64Image(state, action: PayloadAction<string | null>) {
      state.base64Image = action.payload;
    },
    setGroupsError(state, action: PayloadAction<string>) {
      state.groupsError = action.payload;
    },
    setRoleError(state, action: PayloadAction<string>) {
      state.roleError = action.payload;
    },
    setEmailError(state, action: PayloadAction<string>) {
      state.emailError = action.payload;
    },
    setLastNameError(state, action: PayloadAction<string>) {
      state.lastNameError = action.payload;
    },
    setFirstNameError(state, action: PayloadAction<string>) {
      state.firstNameError = action.payload;
    },
    setAccessPermission1(state, action: PayloadAction<boolean>) {
      state.accessPermission1 = action.payload;
    },
    setAccessPermission2(state, action: PayloadAction<boolean>) {
      state.accessPermission2 = action.payload;
    },
    setAccessPermission3(state, action: PayloadAction<boolean>) {
      state.accessPermission3 = action.payload;
    },
    setAccessPermission4(state, action: PayloadAction<boolean>) {
      state.accessPermission4 = action.payload;
    },
    setAccessPermission5(state, action: PayloadAction<boolean>) {
      state.accessPermission5 = action.payload;
    },
    setSelectedFile(state, action: PayloadAction<File | null>) {
      state.selectedFile = action.payload;
    },
  },
});

export const {
  toggleXlsFileChecked,
  setFirstName,
  setLastName,
  setEmail,
  setXlsFileChecked,
  setXlsFiles,
  setXlsFileError,
  setSingleLoading,
  setMultiLoading,
  setRole,
  setGroups,
  setBase64Image,
  setGroupsError,
  setRoleError,
  setEmailError,
  setLastNameError,
  setFirstNameError,
  setAccessPermission1,
  setAccessPermission2,
  setAccessPermission3,
  setAccessPermission4,
  setAccessPermission5,
  setSelectedFile,
} = addUserDataSlice.actions;

export default addUserDataSlice.reducer;

import { Api } from "./apiKit";

interface upgradePayload {
  email: string;
  role: string;
  permission_add_users: boolean;
  permission_review_all_emails: boolean;
  extraPermission1: boolean;
  extraPermission2: boolean;
  extraPermission3: boolean;
}

export async function upgradeUser(data: upgradePayload) {
  const storedSession =
    JSON.parse(localStorage.getItem("cognitoSession") as any) ||
    JSON.parse(sessionStorage.getItem("cognitoSession") as any);

  const resp = await Api.post("/v1/users/upgradeUser", data, {
    headers: {
      authorization: storedSession.idToken,
      "Content-Type": "application/json",
    },
  });

  return resp;
}

export async function createUser(data: FormData) {
  const storedSession =
    JSON.parse(localStorage.getItem("cognitoSession") as any) ||
    JSON.parse(sessionStorage.getItem("cognitoSession") as any);

  const resp = await Api.post("/v1/users/", data, {
    headers: {
      authorization: storedSession.idToken,
      "Content-Type": "multipart/form-data",
    },
  });
  console.log(resp);
  return resp;
}

export async function bulkCreateUser(data: FormData) {
  const storedSession =
    JSON.parse(localStorage.getItem("cognitoSession") as any) ||
    JSON.parse(sessionStorage.getItem("cognitoSession") as any);

  const resp = await Api.post("/v1/users/bulk_create", data, {
    headers: {
      authorization: storedSession.idToken,
      "Content-Type": "multipart/form-data",
    },
  });
  return resp;
}

export async function bulkUpgradeUsers(data: FormData) {
  const storedSession =
    JSON.parse(localStorage.getItem("cognitoSession") as any) ||
    JSON.parse(sessionStorage.getItem("cognitoSession") as any);

  const resp = await Api.post("/v1/users/bulk_upgrade", data, {
    headers: {
      authorization: storedSession.idToken,
      "Content-Type": "multipart/form-data",
    },
  });
  return resp;
}
